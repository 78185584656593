import { Col, DatePicker, Result, Row, Table, Tag, Typography } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DlInput, DlSelect } from '../../components/input';
import { RootState } from '../../redux/store';
import { useFormik } from 'formik';
import subscriptionService from '../../services/subscriptionService';
import { setAllUserSubscriptions, setLimit, setPage, setTotal } from '../../redux/subscriptionReducer';
import { isEmpty, toNumber, toUpper, upperFirst } from 'lodash';
import dateHelpers from '../../utils/helpers/dateHelpers';
import { CreateSubcription } from './modal/createSubscription';
import userHelper from '../../utils/helpers/userHelper';


export function SubscriptionScreen() {

    const [items, setItems] = React.useState<any[]>([])
    const [loading, setLoading] = React.useState(true);
    const [init, setInit] = React.useState(false);
    const dispatch = useDispatch();
    const [filterQuery, setFilterQuery] = React.useState<string>("");
    const filter = useSelector((state: RootState) => state.subscription.filter);
    const currentPage = useSelector((state: RootState) => state.subscription.page);
    const limit = useSelector((state: RootState) => state.subscription.limit);
    const [total,setTotal] = React.useState(0);
    const [payments] = React.useState<any[]>([
        { value: "", title: "Tous les paiements" },
        { value: "subscription", title: "Abonnement" },
        { value: "payment_1", title: "Payment 1" },
        { value: "payment_2", title: "Payment 2" },
        { value: "payment_3", title: "Payment 3" },
    ]);
    const formik = useFormik({
        initialValues: {
            type: "", max: "0", min: "0",lastName:"",firstName:"",
        },
        onSubmit: () => { }
    })

    React.useEffect(() => {
        const { max, min, type } = formik.values;
        const elements = filter;
        if (toNumber(max) > 0) {

        } else {

        }
        if (isEmpty(type)) {

        } else {

        }
        onSearch()
    }, [formik.values])

    const onSearch = React.useCallback(()=>{
        load()
    },[formik.values])

    async function load() {
        const { firstName,lastName } = formik.values;
        let query = `type=subscription&order[validatedAt]=desc&`;
        query+=`&status=in_progress&`
        // filter.forEach((p: any) => {
        //     const { key, value } = p;
        //     if (!isEmpty(key) && !isEmpty(value)) {
        //         query += `${key}=${value}&`;
        //     }
        // });
        if (limit > 0) {
            query += `limit=${limit}&`;
        }
        if (currentPage > 0) {
            query += `page=${currentPage}&`;
        }
        if (!isEmpty(lastName)) {
            query += `user.lastName=${lastName}&`;
        }
        if (!isEmpty(firstName)) {
            query += `user.firstName=${firstName}&`;
        }
        setLoading(true);
        await subscriptionService.getByKey(query).then(response => {
            let data = subscriptionService.getData(response);
            setTotal(subscriptionService.getTotal(response))
            setItems(data);
        }).catch(() => {

        })
        setLoading(false);
    }

    React.useEffect(() => {
        if (items.length === 0) {
            load();
        } else {
            setLoading(false);
        }
    }, [])

    React.useEffect(() => {
        setLoading(true);
        let query = `type=subscription&status=in_progress&order[validatedAt]=desc&`;
        // filter.forEach((p: any) => {
        //     const { key, value } = p;
        //     if (!isEmpty(key) && !isEmpty(value)) {
        //         query += `${key}=${value}&`;
        //     }
        // });
        if (limit > 0) {
            query += `limit=${limit}&`;
        }
        if (currentPage > 0) {
            query += `page=${currentPage}`;
        }
        setFilterQuery(query);

    }, [currentPage, limit, filter])

    React.useEffect(() => {
        if (init) {
            load();
        }
        setInit(true);
    }, [filterQuery])

    return (
        <div className='box'>
            <Typography.Title>
                {`Les abonnements`}
            </Typography.Title>
            <div>
                <Row gutter={16}>
                    <Col flex={1}>
                        <CreateSubcription onload={load} />
                    </Col>
                    {/* <Col>
                        <Typography.Text>
                            {`Période`}
                        </Typography.Text>
                        <div>
                            <DatePicker.RangePicker
                                showTime
                            />
                        </div>
                    </Col> */}
                    {/* <Col>
                        <Typography.Text>
                            {`Type de paiement`}
                        </Typography.Text>
                        <DlSelect
                            formik={formik}
                            list={payments}
                            name="type"
                        />
                    </Col> */}
                    <Col>
                        <DlInput
                            formik={formik}
                            name="lastName"
                            label={"Chercher par nom"}
                        />
                    </Col>
                    <Col>
                        <DlInput
                            formik={formik}
                            name="firstName"
                            label={"Chercher par prénom"}
                        />
                    </Col>
                </Row>
            </div>
            <div>
                <Table
                    dataSource={items}
                    loading={loading}
                    className='yc-table-blue'
                    pagination={total > limit ? {
                        total,
                        pageSize: limit,
                        current: currentPage,
                        defaultCurrent: 1,
                        defaultPageSize: 10,
                        onChange(page, pageSize) {
                            if (currentPage != page) {
                                dispatch(setPage(page));
                            }
                            if (pageSize != limit) {
                                dispatch(setPage(1));
                                dispatch(setLimit(pageSize));
                            }
                        },
                    } : undefined}
                    columns={[
                        {
                            title: "Nom du client",
                            render(value, record, index) {
                                return (
                                    <div>
                                        {`${upperFirst(value?.user?.firstName)} ${toUpper(value?.user?.lastName)}`}
                                    </div>
                                )
                            },
                            align: "center"
                        },
                        {
                            title: "Type de client",
                            render(value) {
                                return (
                                    <div>
                                        {userHelper.isStudent(value?.user) && <Tag color='blue'>{`Etudiant`}</Tag>}
                                        {userHelper.isResponsible(value?.user) && <Tag color='green'>{`Parent`}</Tag>}
                                        {userHelper.isTeacher(value?.user) && <Tag color='gold'>{`Enseignant`}</Tag>}
                                    </div>
                                )
                            },
                            align: "center"
                        },
                        {
                            title: "Date de fin d'abonnement",
                            render(value) {
                                return (
                                    <div>
                                        {`${dateHelpers.getDate(value.validatedAt, "dddd DD MMMM YYYY")}`}
                                    </div>
                                )
                            },
                            align: "center"
                        },
                        {
                            title: "",
                            render(value) {
                                return (
                                    <div>
                                        {/*`${dateHelpers.getDate(value.validatedAt, "dddd DD MMMM YYYY")}`*/}
                                        {dateHelpers.getDifference(value.validatedAt, new Date(),"days")>=0 ? (<i style={{fontSize:20,color:"green"}} className="fa-solid fa-circle-check"></i>):(<i style={{fontSize:20,color:"tomato"}} className="fa-solid fa-circle-xmark"></i>)}
                                    </div>
                                )
                            },
                            align: "center"
                        }
                    ]}
                />
            </div>
        </div>
    )
}