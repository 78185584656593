import { Button, Col, Drawer, Modal, notification, Row, Steps, Typography } from "antd";
import { useFormik } from "formik";
import { isEmpty, toNumber, toUpper } from "lodash";
import React from "react";
import * as yup from "yup";
import { DlInput, DlSelect, DlTextarea } from "../../components/input";
import forecastService from "../../services/forecastService";
import { getUid } from "../../utils/text";

interface createForecastInterface {
  init?: any;
  subject?: any;
  item: any;
  setVisible?:any;
  visible?:boolean;
  branchs?:any[];
  edit?:boolean;
  open?:boolean;
  setOpen?:(v:boolean)=>void;
  itemEditable?:any;
}

interface cmpInterface {
  domaine?: string;
  formik: any;
  branchs?:any[];
}

const { Text } = Typography;

function CreateStep1(props: cmpInterface) {
  const [list, setList] = React.useState<any[]>([]);

  React.useEffect(()=>{
    const elements:any[] = [];
    props.branchs?.forEach(p=>{
      const uid = getUid(p['@id']);
      const title = p.title;
      elements.push({value:uid,title});
    })
    elements.unshift({value:"",title:"Choisir une branche"})
    setList(elements);
  },[props.branchs])
  return (
    <div>
      <div>
        <Text>
          Domaine: <Text strong>{props.domaine}</Text>
        </Text>
      </div>
      <DlInput
        formik={props.formik}
        label="Titre de la leçon"
        name="title"
        placeholder="Titre de la leçon"
      />
      <DlSelect formik={props.formik} name="branch" list={list} label="Branche" />
      <DlTextarea
        formik={props.formik}
        label="Objectif de la prevision"
        name="purpose"
        placeholder="Dites ce que"
        minLength={128}
      />
    </div>
  );
}

function CreateStep2(props: cmpInterface) {
  return (
    <div>
      <div>
        <Text>
          Domaine: <Text strong>{props.domaine}</Text>
        </Text>
      </div>
      <DlTextarea
        formik={props.formik}
        label="Resumé de la leçon"
        name="summary"
        placeholder="Dites de façon brève, le contenu de la leçon"
        minLength={128}
      />
      <DlTextarea
        formik={props.formik}
        label="Autres contenus à consulter"
        name="activities"
        placeholder="Dites ce que"
        minLength={128}
      />
      <DlTextarea
        formik={props.formik}
        label="Les exercices"
        name="generalCulture"
        placeholder="Les exercices liés"
        minLength={256}
      />
    </div>
  );
}

export function CreateForecast(props: createForecastInterface) {
  const { init, subject, item } = props;
  const [loading, setLoading] = React.useState(false);
  const [step, setStep] = React.useState(0);
  const [visible, setVisible] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      branch: "",
      period: null,
      subject: subject,
      title: "",
      purpose: "",
      week: item.week,
      month: item.month,
      year: item.year,
    },
    validationSchema: yup.object({
      purpose: yup.string().required("Champ requis"),
      title: yup.string().required("Champ requis"),
    }),
    onSubmit: handleStep1,
  });

  React.useEffect(() => {
    formik.setValues({
      ...formik.values,
      week: item.week,
      month: item.month,
      year: item.year,
    });
  }, [item]);

  const formik2 = useFormik({
    initialValues: {
      summary: "",
      activities: "",
      generalCulture: "",
    },
    validationSchema: yup.object({
      summary: yup.string().required("Champ requis"),
    }),
    onSubmit: handleStep2,
  });

  function handleStep1() {
    setStep(1);
  }

  async function handleStep2() {
    const { branch, period, purpose, subject, month, week, year, title } =
      Object.assign(formik.values);
    const { activities, generalCulture, summary } = Object.assign(
      formik2.values
    );

    const data: any = {
      branch:!isEmpty(branch)?`/api/branchs/${branch}`:null,
      period,
      purpose,
      subject: `/api/subjects/${subject?.uid ?? subject}`,
      activities,
      generalCulture,
      summary,
      month: toNumber(month),
      week,
      year: toNumber(year),
      title,
    };
    for (const key in data) {
      if (null === data[key]) {
        delete data[key];
      }
    }
    setLoading(true);
    if(props.edit){
      const uid = props.itemEditable?.uid;
      await onUpdate(uid,data);
    }else{
      await onSave(data);
    }
    setLoading(false);
  }

  async function onSave(data:any){
    await forecastService
      .store(data)
      .then(async (response) => {
        notification.info({
          message: "Modification de prévision",
          description: "La modification a réussi",
        });
        if (typeof props.init === "function") {
          await props.init();
        }
        if (typeof setVisible === "function") {
          setVisible(false);
        }
        if (typeof props.setOpen === "function") {
          props.setOpen(false);
        }
        setStep(0);
        formik.resetForm();
        formik2.resetForm();
        setVisible(false);
      })
      .catch((reason) => {
        notification.error({
          message: "Modification de prévision",
          description: "La modification de prévison a échoué",
        });
      });
  }

  async function onUpdate(uid:string,data:any){
    await forecastService
      .update(uid,data)
      .then(async (response) => {
        notification.info({
          message: "Création de prévision",
          description: "La création a réussi",
        });
        if (typeof props.init === "function") {
          await props.init();
        }
        if (typeof setVisible === "function") {
          setVisible(false);
        }
        if (typeof props.setOpen === "function") {
          props.setOpen(false);
        }
        setStep(0);
        formik.resetForm();
        formik2.resetForm();
        setVisible(false);
      })
      .catch((reason) => {
        notification.error({
          message: "Création de prévision",
          description: "La prévison a échoué",
        });
      });
  }

  function handleHide() {
    if (loading) {
      return;
    }
    if (step === 0) {
      if (typeof setVisible === "function") {
        setVisible(false);
      }
      if (typeof props.setOpen === "function") {
        props.setOpen(false);
      }
    } else {
      setStep(step - 1);
    }
  }

  function handleClick() {
    if (step === 0) {
      formik.handleSubmit();
    } else if (step === 1) {
      formik2.handleSubmit();
    }
  }

  function handleShow() {
    setVisible(true);
    if (typeof props.setOpen === "function" && props.edit) {
      props.setOpen(true);
    }
  }

  const onInitItem = React.useCallback(()=>{
    if(props.edit){
      const current = props.itemEditable ?? {};
      formik.setValues({
        ...formik.values,
        purpose:current?.purpose ?? "",
        title:current?.title ?? "",
      })
      formik2.setValues({
        ...formik2.values,
        summary:current?.summary ?? "",
        activities:current?.activities ?? "",
        generalCulture:current?.generalCulture ?? "",
      })
    }
  },[props.itemEditable]);

  React.useEffect(()=>{
    onInitItem();
  },[props.itemEditable]);

  return (
    <React.Fragment>
      {!props.edit && (<Button onClick={handleShow} type="primary">
        {`Ajouter une prévision`}
      </Button>)}
      <Drawer
        title={`${props.edit?"Modifier la provision pour":"Ajouter une prevision pour"} ${toUpper(subject?.title)}`}
        open={props.edit ?props.open: visible}
        //okText={`${step == 0 ? "Suivant" : "Terminer"}`}
        //onOk={handleClick}
        //cancelText={`${step == 0 ? "Annuler" : "Retourner"}`}
        //onCancel={handleHide}
        //confirmLoading={loading}
        onClose={handleHide}
        className="modal__box"
        placement="left"
        width={800}
        style={{minHeight:"100vh"}}
        footer={(
          <Row gutter={8} justify="end">
            <Col>
              <Button disabled={loading} size="large" onClick={handleHide}>
                {`${step == 0 ? "Annuler" : "Retourner"}`}
              </Button>
            </Col>
            <Col>
              <Button loading={loading} disabled={loading} type="primary" size="large" onClick={handleClick}>
                {`${step == 0 ? "Suivant" : "Terminer"}`}
              </Button>
            </Col>
          </Row>
        )}
      >
        <div>
          <div>
            {`Semaine de l'année: ${props.item.week}`}
            <span style={{ padding: "0 10px" }}>
              {`Semaine du mois : `}
              <Text strong>{props.item.monthWeek}</Text>
            </span>
          </div>
          <div style={{ width: 300, margin: "auto", padding: 10 }}>
            <Steps current={step}>
              <Steps.Step />
              <Steps.Step />
            </Steps>
          </div>
          {step === 0 && (
            <CreateStep1 formik={formik} domaine={subject?.title} branchs={props.branchs} />
          )}
          {step === 1 && (
            <CreateStep2 formik={formik2} domaine={subject?.title} />
          )}
        </div>
      </Drawer>
    </React.Fragment>
  );
}
