import { setAllLevels } from "../../redux/levelReducer";
import { setAllStudents } from "../../redux/studentReducer";
import { setAllTeachers } from "../../redux/teacherReducer";
import { setDashboard } from "../../redux/userReducer";
import adminService from "../../services/adminService";
import establishmentService from "../../services/establishmentService";
import fileService from "../../services/fileService";
import lessonService from "../../services/lessonService";
import levelService from "../../services/levelService";
import studentService from "../../services/studentService";
import subjectService from "../../services/subjectService";
import teacherService from "../../services/teacherService";

class DashboardHelper{

    async getStatistic(dispatch:any){
        const teacher = await this.getAllTeachers(dispatch);
        const student = await this.getAllStudents(dispatch);
        const levels = await this.getAllLevels(dispatch);
        const school = await this.getAllSchools(dispatch);
        const university = await this.getAllUniversities(dispatch);
        const agent = await this.getAllAgents(dispatch);
        const subject = await this.getAllCourses(dispatch);
        const lesson = await this.getAllLessons(dispatch);
        const video = await this.getAllVideos(dispatch);
        if(typeof dispatch === "function"){
            dispatch(setDashboard({
                agent: agent,
                course: subject,
                establishment:{all:school + university, school:school, university},
                lesson:lesson,level:levels, student:student, teacher:teacher, video:video
            }))
        }
    }

    private async getAllTeachers(dispatch?:any){
        let result:any = 0;
        await teacherService.getAll().then(response=>{
            result = teacherService.getTotal(response);
            const data = teacherService.getData(response);
            if(typeof dispatch === "function"){
                dispatch(setAllTeachers(data))
            }
        }).catch((reason)=>{

        })
        return result;
    }

    private async getAllStudents(dispatch?:any){
        let result:any = 0;
        await studentService.getAll().then(response=>{
            result = studentService.getTotal(response);
            const data = studentService.getData(response);
            if(typeof dispatch === "function"){
                dispatch(setAllStudents(data))
            }
        }).catch((reason)=>{

        })
        return result;
    }

    private async getAllLevels(dispatch?:any){
        let result:any = 0;
        await levelService.getAll().then(response=>{
            result = levelService.getTotal(response);
            const data = levelService.getData(response);
            if(typeof dispatch === "function"){
                dispatch(setAllLevels(data))
            }
        }).catch((reason)=>{

        })
        return result;
    }

    private async getAllSchools(dispatch?:any){
        let result:any = 0;
        await establishmentService.getByKey(`type=school`).then(response=>{
            result = establishmentService.getTotal(response);
            const data = establishmentService.getData(response);
            if(typeof dispatch === "function"){
                //dispatch(setAllLevels(data))
            }
        }).catch((reason)=>{

        })
        return result;
    }

    private async getAllUniversities(dispatch?:any){
        let result:any = 0;
        await establishmentService.getByKey(`type=university`).then(response=>{
            result = establishmentService.getTotal(response);
            const data = establishmentService.getData(response);
            if(typeof dispatch === "function"){
                //dispatch(setAllLevels(data))
            }
        }).catch((reason)=>{

        })
        return result;
    }

    private async getAllAgents(dispatch?:any){
        let result:any = 0;
        await adminService.getByKey(``).then(response=>{
            result = adminService.getTotal(response);
            const data = adminService.getData(response);
            if(typeof dispatch === "function"){
                //dispatch(setAllLevels(data))
            }
        }).catch((reason)=>{

        })
        return result;
    }

    private async getAllCourses(dispatch?:any){
        let result:any = 0;
        await subjectService.getByKey(``).then(response=>{
            result = subjectService.getTotal(response);
            const data = subjectService.getData(response);
            if(typeof dispatch === "function"){
                //dispatch(setAllLevels(data))
            }
        }).catch((reason)=>{

        })
        return result;
    }

    private async getAllLessons(dispatch?:any){
        let result:any = 0;
        await lessonService.getByKey(``).then(response=>{
            result = lessonService.getTotal(response);
            const data = lessonService.getData(response);
            if(typeof dispatch === "function"){
                //dispatch(setAllLevels(data))
            }
        }).catch((reason)=>{

        })
        return result;
    }

    private async getAllVideos(dispatch?:any){
        let result:any = 0;
        await fileService.getByKey(`typeMime=video`).then(response=>{
            result = fileService.getTotal(response);
            const data = fileService.getData(response);
            if(typeof dispatch === "function"){
                //dispatch(setAllLevels(data))
            }
        }).catch((reason)=>{

        })
        return result;
    }
}

export default new DashboardHelper();