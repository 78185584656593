import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type dashbordType = {
  teacher:number;
  student:number;
  level:number;
  establishment:{
    all:number;
    school:number;
    university:number;
  };
  agent:number;
  course:number;
  lesson:number;
  video:number;
}
export interface userData {
  profil: any;
  current: any;
  list: any[];
  teachers: any[];
  lessons: any[];
  groups: any[];
  agents: any[];
  ready: boolean;
  dashboard:dashbordType
}

const initialState: userData = {
  profil: {},
  current: {},
  list: [],
  teachers: [],
  lessons: [],
  groups: [],
  agents: [],
  ready: false,
  dashboard:{
    agent:0,
    course:0,
    establishment:{
      all:0,school:0,university:0
    },
    lesson:0,
    level:0,
    student:0,
    teacher:0,
    video:0
  }
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserProfil: (state, action: PayloadAction<any>) => {
      state.profil = action.payload;
    },
    setCurrentUser: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setUsers: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setUserTeachers: (state, action: PayloadAction<any[]>) => {
      state.teachers = action.payload;
    },
    setUserLessons: (state, action: PayloadAction<any[]>) => {
      state.lessons = action.payload;
    },
    setUserGroups: (state, action: PayloadAction<any[]>) => {
      state.groups = action.payload;
    },
    setAgents: (state, action: PayloadAction<any[]>) => {
      state.agents = action.payload;
    },
    setReady: (state, action: PayloadAction<boolean>) => {
      state.ready = action.payload;
    },
    setDashboard: (state, action: PayloadAction<dashbordType>) => {
      state.dashboard = action.payload;
    },
  },
});
export const {
  setUserProfil,
  setCurrentUser,
  setUsers,
  setUserGroups,
  setUserLessons,
  setUserTeachers,
  setAgents,
  setReady,
  setDashboard,
} = userSlice.actions;
export default userSlice.reducer;
