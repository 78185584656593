import { UserOutlined } from "@ant-design/icons";
import { Avatar, Col, List, Row, Typography } from "antd";
import { upperCase, upperFirst } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

export function LevelStudentsView() {
  const students = useSelector((state: RootState) => state.level.students);

  const renderItem = (item: any) => {
    return (
      <List.Item>
        <Row align="middle" gutter={8}>
          <Col>
            <Avatar size="large">
              <UserOutlined rev="" />
            </Avatar>
          </Col>
          <Col>
            <Typography.Text strong>
              {`${upperFirst(item?.users?.firstName)} ${upperCase(
                item?.users?.lastName
              )}`}
            </Typography.Text>
          </Col>
          <Col></Col>
        </Row>
      </List.Item>
    );
  };
  return (
    <React.Fragment>
      <List dataSource={students} renderItem={renderItem} />
    </React.Fragment>
  );
}
