import { Button, Modal, notification } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DlSelect } from '../../../components/input';
import userService from '../../../services/userService';
import userHelper from '../../../utils/helpers/userHelper';
import { getUid, getUserName } from '../../../utils/text';
import { isEmpty, uniqueId } from 'lodash';
import subscriptionService from '../../../services/subscriptionService';


export function CreateSubcription(props:{onload:()=>void}){

    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [dating, setDating] = React.useState(false);
    const [items, setItems] = React.useState<any[]>([])
    const [searching, setSearching] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const [current, setCurrent] = React.useState<any>({});

    const payer = useSelector((state:RootState)=>state.user.profil);
    const formik = useFormik({
        initialValues:{
            user:"",
            amount:1
        },
        onSubmit,
        validationSchema:yup.object({
            user:yup.string().required("Champ requis"),
        })

    })

    async function onSubmit() {
        const { user,amount } = formik.values
        const data = {
            payer: payer?.uid ?? getUid(payer["@id"]) ?? "",
            user: user ?? "",
            status: "in_progress",
            merchant:"bo",
            currency:"$",
            amount,
            type: "subscription",
            paymentId: user+(new Date()).getTime()+"fo",
          };
          setLoading(true);
          await subscriptionService
            .store(data)
            .then(async(response) => {
                console.log("REP",response.data)
                await props.onload()
              new Notification("Paiement", {
                body: "Votre paiement a été effectué avec succès",
                icon: require("../../../assets/images/logo-image.png"),
              });
              notification.info({message:"Paiement d'abonnement",description:"Votre paiement a été effectué avec succès"})
              setOpen(false);
              formik.resetForm();
            })
            .catch((reason) =>
              console.log("ERROR paiement", reason?.response ?? reason),
            );
          setLoading(false);
    }

    function handleOpen(){
        setOpen(true);
    }

    function handleClose(){
        setOpen(false)
    }

    function handleSubmit(){
        formik.handleSubmit();
    }

    const onSearch = React.useCallback(async()=>{
        const query = `lastName=${search}&order[lastName]=asc`
        setSearching(true)
        await userService.getByKey(query).then(response=>{
            const data = userService.getData(response)
            const elements:any[] = []
            data.forEach(p=>{
                let title = getUserName(p?.firstName, p?.lastName);
                title+=` (${userHelper.isStudent(p)?"Etudiant,":""}${userHelper.isResponsible(p)?"Parent,":""}${userHelper.isTeacher(p)?"Enseignant,":""})`;
                elements.push({value:p.uid,title})
            })
            setItems(elements)
        }).catch(()=>{})
        setSearching(false)
    },[search])

    const onGetInfo = React.useCallback(async()=>{
        const uid = formik.values.user;
        setCurrent({});
        setDating(true);
        if(!isEmpty(uid)){
            await userService.get(uid).then(response=>{
                setCurrent(response.data);
            }).catch(()=>{})
        }
        setDating(false)
    },[formik.values.user])

    React.useEffect(()=>{
        onSearch()
    },[search])

    React.useEffect(()=>{
        onGetInfo()
    },[formik.values.user])


    return(
        <React.Fragment>
            <Button type='primary' onClick={handleOpen}>
                {`Ajouter un abonnement`}
            </Button>
            <Modal okButtonProps={{disabled:isEmpty(current)}} centered title="Abonnement" confirmLoading={loading} open={open} onCancel={handleClose} onOk={handleSubmit} okText="Enregistrer" cancelText="Annuler" >
                <div>
                    <DlSelect 
                        formik={formik} 
                        label='Bénéficiaire' 
                        name="user" search={true}
                        setSearch={setSearch}
                        loading={searching}
                        list={items} placeholder='Bénéficiaire'
                    />
                    <div>
                        {(!dating && !isEmpty(current))&& (
                            <div style={{padding:10}}>
                                <div>
                                    <span style={{fontWeight:"bold",paddingRight:5}}>Nom:</span>{getUserName(current?.firstName, current?.lastName)}
                                </div>
                                {current?.phone && (<div>
                                    <span style={{fontWeight:"bold",paddingRight:5}}>{`Téléphone:`}</span>{`${current?.phone}`}
                                </div>)}
                                {userHelper.isStudent(current) && (<div>
                                    <span style={{fontWeight:"bold",paddingRight:5}}>{`Classe:`}</span>{`${current?.student?.level?.title ?? "----"}`}
                                </div>)}
                                <div style={{fontSize:22,textAlign:"center",fontWeight:"bold",padding:8}}>
                                    <span style={{fontWeight:"normal",fontSize:14}}>{`Montant à payer :`}</span>{`${formik.values.amount} $`}
                                </div>
                            </div>
                        )}
                    </div>
                    
                </div>
            </Modal>
        </React.Fragment>
    )
}