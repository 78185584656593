import { Button, Modal, notification } from "antd";
import { useFormik } from "formik";
import { isEmpty, range, toString } from "lodash";
import React from "react";
import { DlInput, DlSelect } from "../../components/input";
import groupService from "../../services/groupService";
import levelService from "../../services/levelService";
import * as yup from "yup";
import { getUid } from "../../utils/text";

export function CreateLevel({
  init = {},
  updateId = "",
  currentGroup = null,
  currenttitle = "",
  type = "",
}) {
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [faculties, setFaculties] = React.useState([]);
  const [departments, setDepartments] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const [sections, setSections] = React.useState([]);
  const [optionClass, setOptionClass] = React.useState([]);
  const [cycles, setCycles] = React.useState([]);
  const [promotions, setPromotions] = React.useState([]);
  const school = ["cycle", "section", "optionClass"];
  const univesity = ["faculty", "department", "option"];
  const [step, setStep] = React.useState("root");
  const [group, setGroup] = React.useState(currentGroup);
  const [title, setTitle] = React.useState(null);
  const formik = useFormik({
    initialValues: {
      promotion: -1,
      type: type,
      title: "",
      faculty: null,
      department: null,
      option: null,
      cycle: null,
      section: null,
      optionClass: null,
    },
    validationSchema: yup.object({
      promotion: isEmpty(updateId)
        ? yup.number().required("Champ").min(0, "La valeur n'est pas valide")
        : yup.number().nullable(),
    }),
    onSubmit: isEmpty(updateId) ? handleValidate : handleUpdate,
  });

  function handleClose() {
    if (!loading) {
      setVisible(false);
    }
  }
  async function handleValidate() {
    if (isEmpty(group)) {
      notification.error({
        message: "Saisie",
        description: "Impossible de sauvegarder",
      });
      return;
    }
    const { promotion } = formik.values;
    const data = { promotion, title: title };
    data.groups = `/api/groups/${getUid(group)}`;
    setLoading(true);
    let items = [];
    await levelService
      .getByKey(`promotion=${promotion}&groups=${getUid(group)}`)
      .then((response) => {
        items = levelService.getData(response);
      })
      .catch(() => {});
    if (items.length > 0) {
      notification.warn({
        message: "Ajout de niveau",
        description: "Impossible de d'ajouter ce niveau: il existe déjà",
      });
      setLoading(false);
      return;
    }
    await levelService
      .store(data)
      .then(() => {
        if (typeof init === "function") {
          init();
        }
        notification.info({
          message: "Ajout de niveau",
          description: "Un nouveau niveau est ajouté avec succès",
        });
        setVisible(false);
        initAllSchool();
        initAllUniversity();
        formik.handleReset(() => {});
      })
      .catch((reason) => {
        const error = levelService.getDescription(reason);
        notification.error({
          message: "Ajout de niveau",
          description: isEmpty(error) ? "Erreur lors de l'inssertion" : error,
        });
      });
    setLoading(false);
  }
  async function handleUpdate() {
    if (isEmpty(group)) {
      notification.error({
        message: "Saisie",
        description: "Impossible de sauvegarder",
      });
      return;
    }
    const { promotion } = formik.values;
    const data = {};
    data.groups = `/api/groups/${getUid(group)}`;
    setLoading(true);
    let items = [];
    /*await levelService.getByKey(`promotion=${promotion}&groups=${getUid(group)}`)
      .then(response=>{
        items = levelService.getData(response);
      }).catch(()=>{});
      if(items.length>0){
        notification.warn({
          message:"Ajout de niveau",
          description:"Impossible de d'ajouter ce niveau: il existe déjà"
        })
        setLoading(false);
        return;
      }*/
    await levelService
      .update(toString(getUid(updateId)), data)
      .then(() => {
        if (typeof init === "function") {
          init();
        }
        notification.info({
          message: "Modification de groupement",
          description: "La modification a réussi",
        });
        setVisible(false);
        initAllSchool();
        initAllUniversity();
        formik.handleReset(() => {});
      })
      .catch((reason) => {
        console.log("reason", reason.response);
        const error = levelService.getDescription(reason);
        notification.error({
          message: "Modification de niveau",
          description: isEmpty(error) ? "Erreur lors de l'insertion" : error,
        });
      });
    setLoading(false);
  }
  function initAllUniversity() {
    setFaculties([]);
    setDepartments([]);
    setOptions([]);
    setPromotions([]);
  }
  function initAllSchool() {
    setCycles([]);
    setSections([]);
    setOptionClass([]);
  }
  async function getFaculties() {
    await groupService
      .getByKey(`type=faculty`)
      .then((response) => {
        const data = groupService.getData(response);
        const items = [];
        data.forEach((p) => {
          items.push({ value: getUid(p["@id"]), title: p.name });
        });
        items.unshift({ value: null, title: "Sélectionner la faculté" });
        setFaculties(items);
      })
      .catch((reason) => {
        console.warn(reason);
      });
  }
  async function getCycles() {
    await groupService
      .getByKey(`type=cycle`)
      .then((response) => {
        const data = groupService.getData(response);
        const items = [];
        data.forEach((p) => {
          items.push({ value: getUid(p["@id"]), title: p.name });
        });
        items.unshift({ value: null, title: "Sélectionner le cycle" });
        setCycles(items);
      })
      .catch((reason) => {
        console.warn(reason);
      });
  }

  async function getElements(parent, type) {
    let items = [];
    const uid = getUid(parent);
    await groupService
      .getByKey(`type=${type}&parent=${uid}`)
      .then((response) => {
        const data = groupService.getData(response);
        items = data;
      })
      .catch((reason) => {
        console.log(reason);
      });
    return items;
  }

  function handleChangeFaculty() {
    setDepartments([{ value: null, title: "Sélectionner un département" }]);
    setOptions([{ value: null, title: "Sélectionner une option" }]);
    formik.setValues({
      ...formik.values,
      department: null,
      option: null,
      promotion: -1,
    });
    const id = formik.values.faculty;
    handleLoadGroup(id);
  }

  function handleChangeCycle() {
    setSections([{ value: null, title: "Sélectionnez une section" }]);
    setOptionClass([{ value: null, title: "Sélectionnez une option" }]);
    formik.setValues({
      ...formik.values,
      section: null,
      optionClass: null,
      promotion: -1,
    });
    const id = formik.values.cycle;
    handleLoadGroupSchool(id);
  }

  async function handleLoadGroup(id) {
    setGroup(id);
    if (!isEmpty(id)) {
      let items = await getElements(id, "department");
      console.log("SCHOO", items);
      await getLevels(id);
      if (items.length > 0) {
        const elements = [];
        items.forEach((p) => {
          elements.push({ value: getUid(p["@id"]), title: p.name });
        });
        elements.unshift({ value: null, title: "Sélectionner le département" });
        setDepartments(elements);
        return;
      }
      items = await getElements(id, "option");
      if (items.length > 0) {
        const elements = [];
        items.forEach((p) => {
          elements.push({ value: getUid(p["@id"]), title: p.name });
        });
        elements.unshift({ value: null, title: "Sélectionner une option" });
        setOptions(elements);
        return;
      }
    }
  }

  async function handleLoadGroupSchool(id) {
    setGroup(id);
    if (!isEmpty(id)) {
      let items = await getElements(id, "section");
      await getLevels(id);
      if (items.length > 0) {
        const elements = [];
        items.forEach((p) => {
          elements.push({ value: getUid(p["@id"]), title: p.name });
        });
        elements.unshift({ value: null, title: "Sélectionnez une section" });
        setSections(elements);
        return;
      }
      items = await getElements(id, "option_class");
      if (items.length > 0) {
        const elements = [];
        items.forEach((p) => {
          elements.push({ value: getUid(p["@id"]), title: p.name });
        });
        elements.unshift({ value: null, title: "Sélectionner une option" });
        setOptionClass(elements);
        return;
      }
    }
  }

  async function getLevels(group) {
    const items = [];
    await levelService
      .getByKey(`groups=${group}`)
      .then((response) => {
        const data = levelService.getData(response);
        data.forEach((p) => {
          items.push({ value: getUid(p["@id"]), title: p.title });
        });
      })
      .catch((reason) => console.log("REASON", reason));
    if (items.length > 0) {
      items.unshift({ value: null, title: "Séléctionner la promotion" });
    }
  }

  function handleChangeDeparment() {
    let id = formik.values.department;
    formik.setValues({ ...formik.values, option: null, promotion: -1 });
    if (!isEmpty(id)) {
      setOptions([{ value: null, title: "Sélectionner une option" }]);
      handleLoadGroup(id);
    } else {
      handleChangeFaculty();
    }
  }

  function handleChangeSection() {
    let id = formik.values.section;
    formik.setValues({ ...formik.values, optionClass: null, promotion: -1 });
    if (!isEmpty(id)) {
      setOptionClass([{ value: null, title: "Sélectionner une option" }]);
      handleLoadGroupSchool(id);
    } else {
      handleChangeCycle();
    }
  }

  React.useEffect(() => {
    formik.setValues({ ...formik.values, type });
  }, [type]);

  React.useEffect(() => {
    const value = formik.values.type;
    if (value === "university") {
      initAllUniversity();
      initAllSchool();
      getFaculties();
      setStep("faculty");
    } else if (value === "school") {
      initAllUniversity();
      initAllSchool();
      getCycles();
      setStep("cycle");
    }
  }, [formik.values.type]);
  React.useEffect(() => {
    handleChangeFaculty();
  }, [formik.values.faculty]);

  React.useEffect(() => {
    handleChangeDeparment();
  }, [formik.values.department]);

  React.useEffect(() => {
    handleChangeCycle();
  }, [formik.values.cycle]);

  React.useEffect(() => {
    handleChangeSection();
  }, [formik.values.section]);

  React.useEffect(() => {
    const { faculty, department, option, cycle, section, optionClass, type } =
      formik.values;
    switch (type) {
      case "university":
        let elements = [];
        range(0, 6, 1).forEach((p) => {
          if (p == 0) {
            elements.push({
              value: p,
              title: "Préparatoire",
            });
          } else if (p <= 3) {
            elements.push({
              value: p,
              title: `G${p}`,
            });
          } else {
            elements.push({
              value: p,
              title: `L${p - 3}`,
            });
          }
        });
        if (option != null) {
          setGroup(option);
        } else if (department != null) {
          setGroup(department);
        } else if (faculty != null) {
          setGroup(faculty);
        } else {
          setGroup(null);
          elements = [];
        }
        elements.unshift({ value: -1, title: "Sélectionnez une promotion" });
        setPromotions(elements);
        break;

      case "school":
        let element2 = [];
        let limit = 8;
        let initial = 1;
        console.log("C", cycles);
        const cycleLabel = cycles.find((x) => x.value === cycle)?.title ?? "";
        if (cycleLabel?.toLowerCase()?.includes("prim")) {
          limit = 6;
          initial = 1;
        } else if (cycleLabel?.toLowerCase()?.includes("mat")) {
          limit = 3;
          initial = 1;
        } else if (cycleLabel?.toLowerCase()?.includes("cetb")) {
          initial = 7;
          limit = 8;
        }
        if (cycleLabel?.toLowerCase()?.includes("hum")) {
          limit = 4;
          initial = 1;
        }
        range(initial, limit + 1, 1).forEach((p) => {
          if (p == 1) {
            element2.push({
              value: p,
              title: "1 ère année",
            });
          } else {
            element2.push({
              value: p,
              title: `${p} ème année`,
            });
          }
        });
        if (optionClass != null) {
          setGroup(optionClass);
        } else if (section != null) {
          setGroup(section);
        } else if (cycle != null) {
          setGroup(cycle);
        } else {
          setGroup(null);
          element2 = [];
        }
        element2.unshift({ value: -1, title: "Sélectionnez une promotion" });
        setPromotions(element2);
        break;

      default:
        setGroup(null);
        break;
    }
  }, [formik.values]);

  React.useEffect(() => {
    const promotion = formik.values.promotion;
    const element = promotions.find((p) => p.value == promotion);
    if (!isEmpty(element)) {
      setTitle(element.title);
    }
  }, [formik.values.promotion]);
  return (
    <React.Fragment>
      <Button type="primary" shape="round" onClick={() => setVisible(true)}>
        {type === "school" &&
          `${
            isEmpty(updateId) ? "Ajouter une classe" : "Modifier le groupement"
          }`}
        {type === "university" &&
          `${
            isEmpty(updateId)
              ? "Ajouter une promotion"
              : "Modifier le groupement"
          }`}
      </Button>
      <Modal
        visible={visible}
        centered
        title={
          isEmpty(updateId)
            ? `${
                type === "school"
                  ? "Ajoutez une classe"
                  : "Ajoutez une promotion"
              }`
            : `Modifier le groupement de la ${
                type === "school" ? "classe" : "promotion"
              }`
        }
        okText={isEmpty(updateId) ? "Ajouter" : "Modifier"}
        cancelText="Annuler"
        onCancel={handleClose}
        confirmLoading={loading}
        onOk={() => formik.handleSubmit()}
      >
        <div>
          <DlSelect
            formik={formik}
            name="type"
            enabled={false}
            label="Le type d'établissement"
            list={[
              { value: null, title: "Choisissez un type" },
              { value: "university", title: "Université ou institut" },
              { value: "school", title: "Ecole" },
            ]}
          />
          {univesity.findIndex((p) => step === p) !== -1 &&
            faculties.length > 1 && (
              <DlSelect
                formik={formik}
                name="faculty"
                list={faculties}
                label="Choisir une faculté"
              />
            )}
          {univesity.findIndex((p) => step === p) !== -1 &&
            departments.length > 1 && (
              <DlSelect
                formik={formik}
                name="department"
                label="Choisir le département"
                list={departments}
              />
            )}
          {univesity.findIndex((p) => step === p) !== -1 &&
            options.length > 1 && (
              <DlSelect
                formik={formik}
                name="option"
                label="Choisisses l'option"
                list={options}
              />
            )}
          {school.findIndex((p) => step === p) !== -1 && cycles.length > 1 && (
            <DlSelect
              formik={formik}
              name="cycle"
              list={cycles}
              label="Choisissez le cycle"
            />
          )}
          {school.findIndex((p) => step === p) !== -1 &&
            sections.length > 1 && (
              <DlSelect
                formik={formik}
                list={sections}
                name="section"
                label="Choisissez la section"
              />
            )}
          {school.findIndex((p) => step === p) !== -1 &&
            optionClass.length > 1 && (
              <DlSelect
                formik={formik}
                list={optionClass}
                name="optionClass"
                label="Choisissez l'option"
              />
            )}
          {promotions.length > 1 && isEmpty(updateId) && (
            <DlSelect
              formik={formik}
              name="promotion"
              label="Niveau"
              list={promotions}
            />
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
}
