import { Col, Row } from "antd";
import { useFormik } from "formik";
import React from "react";
import { SchoolTeacherLevels } from "./school";
import Switch from "react-switch";

interface levelListInterface {
  uid?: string;
  list: any[];
  setList?: any;
}

export function SearchLevel(props: levelListInterface) {
  const { list, setList } = props;

  const [loading, setLoading] = React.useState(false);
  const [isSchool, setIsSchool] = React.useState(true);
  const [checked, setChecked] = React.useState(false);

  const formikSchool = useFormik({
    initialValues: { cycle: null, section: null, option: null, classe: null },
    onSubmit: () => {
      console.log("ee");
    },
  });

  return (
    <div>
      <Row align="middle" gutter={4}>
        <Col>
          <span>{`Enseignant pour l'école`}</span>
        </Col>
        <Col>
          <Switch
            checked={isSchool}
            onChange={(checked) => setIsSchool(checked)}
            title="Enseignant pour l'école"
          />
        </Col>
      </Row>
      {isSchool && (
        <SchoolTeacherLevels
          loading={loading}
          setLoading={setLoading}
          formik={formikSchool}
          selecteds={list}
          setLevelSelection={setList}
        />
      )}
    </div>
  );
}
