import { UserAddOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Layout, Table } from "antd";
import { isEmpty, upperCase, upperFirst } from "lodash";
import React from "react";
import { PageHeader } from '@ant-design/pro-layout';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { setAgents } from "../redux/userReducer";
import adminService from "../services/adminService";
import { CreateAgent } from "./agent/create";
import { BreadcrumbComponent } from "../components/breadcrumb";

export function RegisterScreen() {
  const [items, setItems] = React.useState<any[]>([]);
  const agents = useSelector((state: RootState) => state.user.agents);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  async function init() {
    setLoading(true);
    await adminService
      .getByKey(`order[user.firstName]=asc&order[user.lastName]=asc&limit=1000`)
      .then((response) => {
        const data = adminService.getData(response);
        dispatch(setAgents(data));
      })
      .catch((reason) => {});
    setLoading(false);
  }

  React.useEffect(() => {
    init();
  }, []);

  React.useEffect(() => {
    const elements: any[] = [];
    agents.forEach((p) => {
      elements.push({
        uid: p.uid,
        name: `${upperFirst(p.user?.firstName)} ${upperCase(p.user?.lastName)}`,
        photo: p.user?.photo,
        email: p.user?.email,
        roles: p.user?.auth?.roles,
      });
    });
    setItems(elements);
  }, [agents]);

  return (
    <Layout>
      <BreadcrumbComponent title="Les agents">
      <CreateAgent init={init} />
      </BreadcrumbComponent>
      <Table
        loading={loading}
        dataSource={items}
        className="table__1"
        columns={[
          {
            title: "",
            dataIndex: "photo",
            width: 100,
            align: "center",
            render(value, record, index) {
              return (
                <Avatar src={value}>
                  {isEmpty(value) && <UserOutlined rev="" />}
                </Avatar>
              );
            },
          },
          { title: "Nom de l'agent", dataIndex: "name" },
          { title: "Email", dataIndex: "email" },
          { title: "Rôles", dataIndex: "role" },
          { title: "", dataIndex: "action" },
        ]}
      />
    </Layout>
  );
}
