import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSchoolFilters, setSchoolLevels } from "../redux/levelSchoolReducer";
import { RootState } from "../redux/store";
import groupService from "../services/groupService";
import levelService from "../services/levelService";
import { getLevel, getLevelData, LevelScreen } from "./listLevels";

export function SchoolScreen() {
  const levels = useSelector((state: RootState) => state.school.list);
  const levelFilters = useSelector((state: RootState) => state.school.filters);
  const [loading, setLoading] = React.useState(false);
  const [cycles, setCycles] = React.useState<any[]>([]);
  const [sections, setSections] = React.useState<any[]>([]);
  const [options, setOptions] = React.useState<any[]>([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    init();
  }, []);

  async function init() {
    groupService
      .getByKey(`type=cycle`)
      .then((response) => {
        const data = groupService.getData(response);
        setCycles(data);
      })
      .catch(() => {});
    if (levels.length > 0) {
      return;
    }
    const filters: any[] = [];
    filters.push({ item: "groups.type[]", value: "cycle" });
    filters.push({ item: "groups.type[]", value: "section" });
    filters.push({ item: "groups.type[]", value: "option_class" });
    filters.push({ item: "order[groups.type]", value: "asc" });
    filters.push({ item: "order[groups.name]", value: "asc" });
    filters.push({ item: "order[promotion]", value: "asc" });
    filters.push({ item: "limit", value: "10" });
    dispatch(setSchoolFilters(filters));
  }

  async function getLevels() {
    if (levelFilters.length === 0) {
      return;
    }
    let query = ``;
    levelFilters.forEach((p) => {
      query += `${p.item}=${p.value}&`;
    });
    setLoading(true);
    await levelService
      .getByKey(query)
      .then((response) => {
        const data = levelService.getData(response);
        const items = getLevelData(data);
        dispatch(setSchoolLevels(items));
      })
      .catch((reason) => {});
    setLoading(false);
  }

  React.useEffect(() => {
    getLevels();
  }, [levelFilters]);

  return (
    <LevelScreen
      type="school"
      levels={levels}
      loading={loading}
      init={init}
      groupLevel1={cycles}
      groupLevel2={sections}
      groupLevel3={options}
      setGroupLevel1={setCycles}
      setGroupLevel2={setSections}
      setGroupLevel3={setOptions}
    />
  );
}
