import { Avatar, Col, Row, Segmented, Skeleton, Tabs, Tag, Typography } from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import { addDays, isSameMonth, startOfWeek, getWeek, format } from "date-fns";
import React from "react";
import { useParams, useMatch, useLocation, Link } from "react-router-dom";
import forecastService from "../services/forecastService";
import dateHelpers from "../utils/helpers/dateHelpers";
import { WeekView } from "./forecast/week";
import { CreateForecast } from "./forecast/create";
import subjectService from "../services/subjectService";
import subjectHelper from "../utils/helpers/subjectHelper";
import { getUid } from "../utils/helpers/title";
import { BreadcrumbComponent } from "../components/breadcrumb";
import { isEmpty } from "lodash";

export function ForecatMonth() {
  const loc = useLocation();
  const [month, setMonth] = React.useState(0);
  const [year, setYear] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [allWeeks, setAllWeeks] = React.useState<any[]>([]);
  const [params, setParams] = React.useState<any>({});
  const [subject, setSubject] = React.useState<any>({});
  const [branchs, setBranchs] = React.useState<any>([]);
  const [segment, setSegment] = React.useState<any>("0");
  const [segments, setSegments] = React.useState<any[]>([]);

  async function init() {
    if(month===0 || year ===0){
      return;
    }
    const currentMonth = new Date(year, month - 1);
    const weeks: any[] = [];
    let isSame = true;
    let i = 0;
    let k = 1;
    let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    isSame = isSameMonth(currentMonth, startDate);
    if (!isSame) {
      const week = getWeek(addDays(startDate, i));
      const items = await getForecats(week);
      weeks.push({
        start: dateHelpers.getDate(addDays(startDate, i), "DD MMMM YYYY"),
        end: dateHelpers.getDate(addDays(startDate, i + 6), "DD MMMM YYYY"),
        week: getWeek(addDays(startDate, i)),
        monthWeek: k,
        month: startDate.getMonth(),
        year: startDate.getFullYear(),
        items,
      });
      i += 7;
      k++;
      const date = addDays(startDate, i);
      isSame = isSameMonth(currentMonth, date);
    }
    while (isSame) {
      const date = addDays(startDate, i);
      isSame = isSameMonth(currentMonth, date);
      if (isSame) {
        const week = getWeek(addDays(startDate, i));
        const items = await getForecats(week);
        weeks.push({
          start: dateHelpers.getDate(addDays(startDate, i), "DD MMMM YYYY"),
          end: dateHelpers.getDate(addDays(startDate, i + 6), "DD MMMM YYYY"),
          week,
          monthWeek: k,
          month: date.getMonth(),
          year: date.getFullYear(),
          items,
        });
      }
      i += 7;
      k++;
    }
    const elements:any[] = [];
    weeks.forEach((p,index)=>{
      elements.push({
        value:`${index}`,
        label:(
          <div style={{lineHeight:"20px",padding:3}}>
            <div>
              {p.start}
            </div>
            <div>
              {`au`}
            </div>
            <div>
              {p.end}
            </div>
          </div>
        )
      })
    })
    setSegments(elements);
    setAllWeeks(weeks);
    setLoading(false);
  }

  async function getForecats(week: number) {
    let data: any[] = [];
    const query = `year=${year}&week=${week}&subject=${params.subject}`;
    console.log("QUERY", query);
    await forecastService
      .getByKey(query)
      .then((response) => {
        data = forecastService.getData(response);
      })
      .catch((reason) => {
        console.log("REASON", reason.response ?? reason);
      });
    return data;
  }

  React.useEffect(() => {
    let search = loc.search;
    const date: any = {};
    if (search.length > 0) {
      search = search.substring(1, search.length);
    }
    const elements = search.split("&");
    elements.forEach((p) => {
      const item = p.split("=");
      date[item[0]] = item[1];
    });
    setMonth(date.month);
    setYear(date.year);
    setParams(date);
    subjectService
      .get(date?.subject)
      .then(async(response) => {
        const elem = response.data;
        setSubject(elem);
        const elements = await subjectHelper.getBranchs(getUid(elem['@id']));
        setBranchs(elements);
      })
      .catch((reason) => {});
  }, [loc]);

  React.useEffect(() => {
    onInit();
  }, [month, year]);

  const onInit = React.useCallback(()=>{
    setLoading(true);
    init();
  },[month,year])

  function onLoad() {
    init();
  }

  return (
    <React.Fragment>
      <BreadcrumbComponent title="Gestion des prévisions dans une matière">
        <Row gutter={16}>
          <Col>
          <div>
          <Typography.Text style={{textTransform:"uppercase"}}>
            {`Année`}
          </Typography.Text>
          {!isEmpty(subject) && (<div>
          <Typography.Text>
            {year}
          </Typography.Text>
          </div>)}
        </div>
          </Col>
          <Col>
          <div>
          <Typography.Text style={{textTransform:"uppercase"}}>
            {`Mois`}
          </Typography.Text>
          {!isEmpty(subject) && (<div>
          <Typography.Text style={{textTransform:"uppercase"}}>
            {dateHelpers.getDate(new Date(year,month-1),"MMMM")}
          </Typography.Text>
          </div>)}
        </div>
          </Col>
          <Col>
          <div>
          <Typography.Text>
            {`TITRE DU COURS`}
          </Typography.Text>
          {!isEmpty(subject) && (<div>
          <Typography.Text>
            <Link to={`/admin/subjects/${params.subject}/view`}>
            {subject?.title}
            </Link>
          </Typography.Text>
          </div>)}
        </div>
          </Col>
        </Row>
      </BreadcrumbComponent>
      <div style={{marginBottom:8}} >
        <Typography.Text style={{display:"block"}}>
          {`Veuillez basculer autour des semaines`}
        </Typography.Text>
      <Segmented
          options={segments}
          className="card__4"
          onChange={v=>setSegment(v)}
          value={segment}
      />
      </div>
      <section className="box">
        {!loading && (
          <div>
            <Tabs animated={false} size="large" tabPosition="left" centered>
            {/*allWeeks.map((p: any, index: number) => {
              console.log("P", p);
              return (
                <Tabs.TabPane
                  tab={
                    <Row align="middle" gutter={8}>
                      <Col>
                        <Tag color={p.items?.length > 0 ? "green" : "red"}>
                          {p.items?.length}
                        </Tag>
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          height: 60,
                        }}
                      >
                        <div style={{ padding: 0, margin: 0 }}>{p.start}</div>
                        <div
                          style={{ flex: 1, borderLeft: "solid 2px #082" }}
                        />
                        <div>{p.end}</div>
                      </Col>
                    </Row>
                  }
                  key={index + ""}
                >
                  <WeekView
                    items={p.items}
                    branchs={branchs}
                    month={month}
                    week={p.week}
                    monthWeek={p.monthWeek}
                    year={year}
                    getForecats={onLoad}
                    subject={subject}
                    element={{
                      title: "hjkl",
                    }}
                  />
                </Tabs.TabPane>
              );
            })*/}
          </Tabs>
          {allWeeks.map((p: any, index: number) => {
            return(
              <div>
                {segment === `${index}` && (
                  <WeekView
                  items={p.items}
                  branchs={branchs}
                  month={month}
                  week={p.week}
                  monthWeek={p.monthWeek}
                  year={year}
                  getForecats={onLoad}
                  subject={subject}
                  element={{
                    title: "hjkl",
                  }}
                />
                )}
              </div>
            )
          })}
          </div>
        )}
        {loading && (
          <React.Fragment>
            <Skeleton active />
            <Skeleton active />
            <Skeleton />
            <Skeleton active />
            <Skeleton active />
          </React.Fragment>
        )}
      </section>
    </React.Fragment>
  );
}
