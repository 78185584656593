import { Table, Typography } from "antd";
import { upperFirst } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface otherInterface {
  items: any[];
}

const { Text } = Typography;

export function BranchSubject(props: otherInterface) {
  return (
    <Table
      dataSource={props.items}
      columns={[
        {
          title: "Titre",
          dataIndex: "title",
          align: "center",
          render(value, record, index) {
            return <div>{upperFirst(value)}</div>;
          },
        },
        {
          title: "Matière",
          dataIndex: "subject",
          align: "center",
          render(value, record, index) {
            return <div>{upperFirst(value?.title)}</div>;
          },
        },
        {
          title: "Previsions",
          dataIndex: "forecats",
          align: "center",
          render(value, record, index) {
            return (
              <Text
                style={{
                  display: "inline-block",
                  textAlign: "center",
                  width: "100%",
                }}
                strong
                type="secondary"
              >
                {value?.length}
              </Text>
            );
          },
        },
      ]}
    />
  );
}
