import { Breadcrumb, Col, Row, Typography } from "antd";
import React, { PropsWithChildren } from "react";
import { colorAccent, colorPrimary } from "../utils/theme/color";
import { useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import { getRoutesForBreadcrumb } from "../routes";
import useBreadcrumbs from "use-react-router-breadcrumbs";


export const BreadcrumbComponent:React.FC<
PropsWithChildren<{title?:string;}>
>=({title,children})=>{

    const [items, setItems] = React.useState<any[]>([]);
    const breadcrumbs = useBreadcrumbs(getRoutesForBreadcrumb());

    const route = useLocation();

    const onLocation = React.useCallback(()=>{
        const links = route.pathname.split("/");
        const elements:any[] = [];
        breadcrumbs.forEach((p:any,i)=>{
            if(!isEmpty(p?.match?.route)){
                if(i<links.length-1){
                    elements.push({
                        title: <a href={`/${p?.match?.route?.path}`}>{p?.match?.route?.label}</a>
                    })
                }else{
                    elements.push({title:p?.match?.route?.label});
                }
            }
        })
        setItems(elements);

    },[route])

    React.useEffect(()=>{
        onLocation();
    },[route])

    return(
        <div className="card__5" style={{padding:18,borderRadius:16,marginBottom:8}}>
            <Row align="middle" gutter={16}>
                <Col flex={1}>
                    <Typography.Title style={{fontSize:18}}>
                        {title}
                    </Typography.Title>
                    <Breadcrumb items={items} />
                </Col>
                <Col>
                {children}
                </Col>
            </Row>
        </div>
    )
}