import {
  Badge,
  Button,
  Col,
  Divider,
  Layout,
  Menu,
  Row,
  Typography,
} from "antd";
import { toUpper } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import routes from "../../routes";
import { getDate } from "../../utils/DateHelpers";
import { appName } from "../../utils/helpers/title";
import { getUserName } from "../../utils/text";
import {
  LogoutButton,
  NotificationButton,
  ProfilButton,
  SettingButton,
} from "../atoms/button";
import { SearchInput } from "../atoms/SearchInput";
import { NotificationSpace } from "../organism/notification";

const { Text, Title } = Typography;
const { Header } = Layout;

function AdminNavbar() {
  const user = useSelector((state: RootState) => state.user.profil);

  return (
    <header
      style={{
        position: "sticky",
        padding: "0.7rem",
        width: "100%",
        background: "#f0f0f0",
        top: 0,
        zIndex: 14,
        borderRadius: 0,
      }}
      className="yc-card"
    >
      <Row
        align="middle"
        wrap={false}
        gutter={8}
        style={{ position: "relative" }}
      >
        <Col>
          <div>
            {`${toUpper(appName)} `}
            <span>ADMIN</span>
          </div>
        </Col>
        <Col flex={1}>
          <Row gutter={2} align="middle" justify="end" wrap={false}>
            <Col>
              <Button type="text">
                <i className="fa-duotone fa-grid yc_icon_admin"></i>
              </Button>
            </Col>
            <Col>
              <Badge>
                <Button type="text">
                  <i className="fa-solid fa-bell yc_icon_admin"></i>
                </Button>
              </Badge>
            </Col>
          </Row>
        </Col>
      </Row>
    </header>
  );
}
export { AdminNavbar };
