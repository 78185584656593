import { CameraFilled } from "@ant-design/icons";
import {
  Avatar,
  Button,
  message,
  Modal,
  notification,
  Row,
  Upload,
} from "antd";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { isEmpty } from "lodash";
import React from "react";
import subjectService from "../../services/subjectService";
import { getUid } from "../../utils/text";

interface updatePosterSubjectInterface {
  item: any;
  initFunction?: any;
}

export function UpdatePosterSubject(props: updatePosterSubjectInterface) {
  const { item, initFunction } = props;

  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [obj, setObj] = React.useState<Blob | null>(null);
  const [preview, setPreview] = React.useState<string | null>(null);
  function handleOpen() {
    setVisible(true);
  }
  function handleClose() {
    if (!loading) {
      setVisible(false);
    }
  }
  async function handleUpload(info: any) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (!isEmpty(info.file.originFileObj)) {
      setObj(info.file.originFileObj);
      const file = info.file as UploadFile;
      onPreview(file);
      setLoading(false);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  }

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    setPreview(src);
  };

  async function handleUpdate() {
    const uid = getUid(item["@id"]) as string;
    if (!isEmpty(obj)) {
      setLoading(true);
      const data = new FormData();
      const element = obj as Blob;
      data.append("uid", uid);
      data.append("file", element);
      await subjectService
        .updatePoster(data)
        .then((response) => {
          notification.info({
            message: "Modification",
            description: "Réussite",
          });
          if (typeof initFunction === "function") {
            initFunction();
          }
          setObj(null);
          setPreview(null);
          setVisible(false);
        })
        .catch((reason) => {
          notification.error({ message: "La modification a échoué" });
        });
      setLoading(false);
    } else {
      notification.warning({
        message: "Modification",
        description: "Impossible de modifier sans image",
      });
    }
  }

  return (
    <React.Fragment>
      <Button type="text" className="yc-btn-left" onClick={handleOpen}>
        {"Modifier l'affiche"}
      </Button>
      <Modal
        visible={visible}
        confirmLoading={loading}
        centered
        title="Modifier l'affiche de matière"
        okText={"Modifier"}
        onOk={handleUpdate}
        cancelText="Annuler"
        onCancel={handleClose}
      >
        <Row align="middle" justify="center">
          <Avatar
            shape="square"
            size={300}
            src={preview ?? item.poster}
          ></Avatar>
          <div className="yc-profil-camera">
            <Upload
              disabled={loading}
              onChange={handleUpload}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              multiple={false}
              accept="image/*"
              //isImageUrl={false}
              showUploadList={false}
            >
              <Button type="text" disabled={loading}>
                <CameraFilled style={{ fontSize: "20px" }} rev="" />
              </Button>
            </Upload>
          </div>
        </Row>
      </Modal>
    </React.Fragment>
  );
}
