import { Carousel, Col, Layout, Row, Steps, Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { RenderGroup } from "../../components/renderGroup";
import { RenderGroupStep } from "../../components/renderGroupStep";
import { RootState } from "../../redux/store";

export function ShowLevel() {
  const level = useSelector((state: RootState) => state.level.current);

  return (
    <React.Fragment>
      <Layout style={{ minHeight: "100vh" }}>
        <Typography.Title
          level={2}
          className="yc_text_primary"
          style={{ textAlign: "center" }}
        >
          {level?.title}
        </Typography.Title>
        <Row>
          <Col span={6}>
            <RenderGroupStep group={level?.groups} />
          </Col>
          <Col span={18}>
            <Carousel></Carousel>
          </Col>
        </Row>
      </Layout>
    </React.Fragment>
  );
}
