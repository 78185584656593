import { Steps, Tag, Timeline } from "antd";
import { isEmpty } from "lodash";
import React from "react";

interface renderGroupInterface {
  group: any;
}

const RenderStep = (props: renderGroupInterface) => {
  console.log("PROPS", props.group);
  return <Steps.Step title={props.group?.name} />;
};

export function RenderGroupStep(props: renderGroupInterface) {
  const [items, setItems] = React.useState<any[]>([]);
  React.useEffect(() => {
    setItems(getGroups(props.group));
  }, [props.group]);

  function getGroups(group: any) {
    const elements: any[] = [];
    elements.push(group);
    if (!isEmpty(group?.parent)) {
      const subElements = getGroups(group?.parent);
      subElements.forEach((p) => {
        elements.push(p);
      });
    }
    return elements;
  }
  const getColor = (type: string): string => {
    switch (type) {
      case "cycle":
        return "cyan";
      case "faculty":
        return "cyan";
      case "section":
        return "orange";
      case "department":
        return "orange";
      case "option":
        return "lime";
      case "option_class":
        return "lime";
      default:
        return "blue";
    }
  };

  const getTitle = (type: string): string => {
    switch (type) {
      case "cycle":
        return "le nom du cycle";
      case "faculty":
        return "le nom de la faculté";
      case "section":
        return "le nom de la section";
      case "department":
        return "le nom de département";
      case "option":
        return "le nom de l'option";
      case "option_class":
        return "le nom de l'option";
      default:
        return "";
    }
  };

  return (
    <React.Fragment>
      <Timeline>
        {items.map((p) => (
          <Timeline.Item>
            <div style={{ height: "100px" }}>{p?.name}</div>
          </Timeline.Item>
        ))}
      </Timeline>
    </React.Fragment>
  );
}
