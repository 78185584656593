import { Button, Layout, Skeleton, Tabs } from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SubjectView } from "./subjectContent";
import { RootState } from "../redux/store";
import {
  setCurrent,
  setLevelStudents,
  setLevelTeachers,
} from "../redux/levelReducer";
import levelService from "../services/levelService";
import { range, toString } from "lodash";
import { ShowLevel } from "./level/showing";
import { UpdateTitleLevel } from "./level/updateTitle";
import { CreateLevel } from "./level/create";
import { getUid } from "../utils/text";
import { LevelStudentsView } from "./level/student";
import studentService from "../services/studentService";
import { TeacherLevel } from "./level/teachers";
import levelHelper from "../utils/helpers/levelHelper";
import { ForecastLevel } from "./level/forecast";

export function LevelView() {
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const level = useSelector((state: RootState) => state.level.current);

  React.useEffect(() => {
    init();
  }, [params]);

  async function init() {
    const { id } = params;
    setLoading(true);
    dispatch(setLevelStudents([]));
    dispatch(setLevelTeachers([]));
    studentService
      .getByKey(
        `level=${id}&order[users.firstName]=asc&order[users.lastName]=asc`
      )
      .then((response) => {
        const data = studentService.getData(response);
        dispatch(setLevelStudents(data));
      })
      .catch(() => {
        dispatch(setLevelStudents([]));
      });
    levelHelper.getTeachers(id as string, dispatch);
    await levelService
      .get(toString(id))
      .then((response) => {
        dispatch(setCurrent(response.data));
      })
      .catch((reason) => console.warn(reason));
    setLoading(false);
  }

  return (
    <React.Fragment>
      {loading && (
        <React.Fragment>
          {range(1, 6).map(() => (
            <Skeleton active />
          ))}
        </React.Fragment>
      )}
      {!loading && (
        <Layout className="box" style={{ padding: 10 }}>
          <PageHeader
            onBack={() => window.history.back()}
            title={level.title}
            extra={[
              <CreateLevel
                currenttitle={level.title}
                updateId={toString(getUid(level["@id"]))}
              />,
              <UpdateTitleLevel />,
            ]}
          ></PageHeader>
          <Tabs animated centered type="card">
            <Tabs.TabPane key="1" tab="Présentation">
              <ShowLevel />
            </Tabs.TabPane>
            <Tabs.TabPane key="2" tab="Les matières">
              <SubjectView />
            </Tabs.TabPane>
            <Tabs.TabPane key="3" tab="Les étudiants">
              <LevelStudentsView />
            </Tabs.TabPane>
            <Tabs.TabPane key="4" tab="Prévisions de matières">
              <ForecastLevel />
            </Tabs.TabPane>
            <Tabs.TabPane key="5" tab="Enseignants">
              <TeacherLevel />
            </Tabs.TabPane>
          </Tabs>
        </Layout>
      )}
    </React.Fragment>
  );
}
