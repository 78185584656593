import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getUid } from "../../utils/text";

interface gotoInterface {
  item: any;
  buttonType?:"link" | "text" | "default" | "dashed" | "primary"
}
export function GoToForecast(props: gotoInterface) {
  const { item,buttonType } = props;

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Button onClick={()=>{
        navigate(`/admin/forecasts/${getUid(item["@id"])}/view`);
      }} type={buttonType ?? "text"} className="yc-btn-left">
        {`Allez aux previsions`}
      </Button>
    </React.Fragment>
  );
}
