import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface subscriptionData {
  current: any; // current subscription
  list: any[]; //subscriptions list
  all: any[]; //all subscriptions
  subscriptions:any[];//all subscriptions, type subscription
  filter:string[];
  itemCount:number;
  page:number;
  limit:number;
}

const initialState: subscriptionData = {
  current: {},
  list: [],
  all: [],
  filter:[],
  subscriptions:[],
  itemCount:0,
  limit:10,
  page:1
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setAllUserSubscriptions: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
    setSubscriptions: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setSubscriptionList: (state, action: PayloadAction<any[]>) => {
      state.subscriptions = action.payload;
    },
    setCurrentSubscription: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setFilter: (state, action: PayloadAction<string[]>) => {
      state.filter = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.itemCount = action.payload;
    },
  },
});
export const {
  setAllUserSubscriptions,
  setSubscriptions,
  setCurrentSubscription,
  setFilter,setLimit,setPage,setSubscriptionList, setTotal,
} = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
