import { Button, Modal, notification } from "antd";
import { useFormik } from "formik";
import React from "react";
import { DlInput, DlTextarea } from "../../components/input";
import subjectService from "../../services/subjectService";
import { isEmpty, toString } from "lodash";
import { getUid } from "../../utils/text";

interface createSubjectInterface {
  id?: number | string;
  initFunction?: any;
  item?: any;
}

export function CreateSubject(
  props: createSubjectInterface = { id: 0, initFunction: {}, item: {} }
) {
  const { id, initFunction, item } = props;

  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      title: item?.title ?? "",
      category: null,
      level: `/api/levels/${id}`,
      state: "in_progress",
      description: item?.description ?? "",
    },
    onSubmit: isEmpty(item) ? handleValidate : handleUpdate,
  });
  function handleOpen() {
    setVisible(true);
  }
  function handleClose() {
    if (!loading) {
      setVisible(false);
    }
  }
  async function handleValidate() {
    setLoading(true);
    const data = formik.values;
    await subjectService
      .store(data)
      .then(() => {
        notification.info({
          message: "Ajoute de la matière",
          description: `${formik.values.title} a été ajouté avec succès`,
        });
        if (typeof initFunction === "function") {
          initFunction();
        }
        setVisible(false);
        formik.handleReset((e: any) => {});
      })
      .catch((reason) => {
        console.log("REASON", reason);
        notification.error({
          message: "Erreur d'enegistrement",
          description: "L'enregistrement a échoué",
        });
      });
    setLoading(false);
  }
  async function handleUpdate() {
    setLoading(true);
    const { title, description } = formik.values;
    const data = { title, description };
    const uid = getUid(item["@id"]);
    await subjectService
      .update(toString(uid), data)
      .then(() => {
        notification.info({
          message: "Modification de la matière",
          description: `La modification a réussi`,
        });
        if (typeof initFunction === "function") {
          initFunction();
        }
        setVisible(false);
        formik.handleReset((e: any) => {});
      })
      .catch((reason) => {
        console.log("REASON", reason);
        notification.error({
          message: "Erreur de modification",
          description: "La modification a échoué",
        });
      });
    setLoading(false);
  }
  return (
    <React.Fragment>
      {isEmpty(item) && (
        <Button onClick={handleOpen}>Ajouter une matière</Button>
      )}
      {!isEmpty(item) && (
        <Button type="text" className="yc-btn-left" onClick={handleOpen}>
          Modifier le cours
        </Button>
      )}
      <Modal
        visible={visible}
        confirmLoading={loading}
        centered
        title={isEmpty(item) ? "Nouvelle matière" : "Modifier la matière"}
        okText={isEmpty(item) ? "Enregistrer" : "Modifier"}
        onOk={() => formik.submitForm()}
        cancelText="Annuler"
        onCancel={handleClose}
      >
        <div>
          <DlInput
            formik={formik}
            name="title"
            label="Titre de la matière"
            placeholder="titre de la matière"
          />
          <DlTextarea
            formik={formik}
            name="description"
            label="Description du cours"
          />
        </div>
      </Modal>
    </React.Fragment>
  );
}
