import { Button, Card, Col, Layout, notification, Row, Typography } from "antd";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { DlInput, DlPassword } from "../components/input";
import userService from "../services/userService";
import userHelper from "../utils/helpers/userHelper";

export function LoginScreen() {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .required("Champ requis")
        .email("Veuillez écrire votre email correctement"),
    }),
    onSubmit: onLogin,
  });

  async function authUser(code: string, password: string) {
    let data = null;
    await userService
      .login({ username: code, password: password })
      .then((response) => {
        data = response.data;
      })
      .catch(() => {
        data = null;
      });
    return data;
  }

  async function onLogin() {
    const data = formik.values;
    setLoading(true);
    await userService.getByKey(`email=${data.email}`).then(async (response) => {
      const elements = userService.getData(response);
      if (elements.length > 0) {
        const element = elements[0];
        if (!userHelper.isAdmin(element)) {
          notification.warning({
            duration: 3,
            message: "Connexion au compte",
            description:
              "Vous n'êtes pas un administrateur: veuillez contacter la hierarchie.",
          });
          setLoading(false);
          return;
        }
        let login: any = await authUser(element.code, data.password);
        if (isEmpty(login)) {
          login = await authUser(element.code, element.code);
        }
        if (isEmpty(login)) {
          notification.warning({
            message: "Connexion",
            description: "Veuillez vérifier vos identifiants",
          });
          setLoading(false);
        } else {
          userHelper.setToken(login.token);
          userHelper.setData(element, dispatch);
          window.location.href = "/admin";
        }
      } else {
        notification.warning({
          message: "Connexion",
          description: "Veuillez vérifier vos identifiants",
        });
        setLoading(false);
      }
    });
  }
  return (
    <Layout
      className="yc-bg-home"
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{ minWidth: 500, padding: 0, background: "#fff" }}
        className="yc-card"
      >
        <div
          style={{ width: "100%", textAlign: "center", padding: "10px 0 5px" }}
        >
          <img
            src={require("../assets/images/logo.png")}
            style={{ textAlign: "center", width: 100 }}
          />
        </div>
        <p style={{ textAlign: "center" }}>
          {`Veuillez vous connecter à votre compte ci-dessous.`}
        </p>
        <div style={{ padding: "5px 15px 20px" }}>
          <div style={{ paddingTop: 5 }}>
            <DlInput
              placeholder="your@domain.com"
              type="email"
              formik={formik}
              name="email"
              label="E-mail"
            />
          </div>
          <div style={{ paddingTop: 10 }}>
            {/*<DlPassword
              label="Mot de passe"
              formik={formik}
              name="password"
              placeholder="Mot de passe ici"
    />*/}
          </div>
        </div>
        <Row
          style={{
            background: "#f1f1f1",
            padding: "15px 10px",
            borderBottomLeftRadius: 10,
            WebkitBorderBottomRightRadius: 10,
          }}
          justify="end"
        >
          <Row gutter={4} align="middle">
            <Col>
              <Button
                disabled={loading}
                loading={loading}
                onClick={() => formik.handleSubmit()}
                type="primary"
              >
                {`Se connecter`}
              </Button>
            </Col>
          </Row>
        </Row>
      </div>
    </Layout>
  );
}
