import { Card, Layout, List, Statistic } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

export function StatStructure(){

    const dashboard = useSelector((state:RootState)=>state.user.dashboard);
    const [items, setItems] = React.useState<any[]>([]);

    function init(){
        const elements:any[] = [];
        elements.push({
            title: "Enseignants",
            value: dashboard.teacher,
            icon:"fa-solid fa-person-chalkboard",
            color:"#098d03",
        });
        elements.push({
            title: "Etudiants",
            value: dashboard.student,
            icon:"fa-solid fa-screen-users",
            color:"#5c987a",
        });
        elements.push({
            title: "Ecole",
            value: dashboard.establishment.school,
            icon:"fa-solid fa-school",
            color:"#faad14",
        });
        elements.push({
            title: "Université",
            value: dashboard.establishment.university,
            icon:"fa-solid fa-graduation-cap",
            color:"#333",
        });
        setItems(elements);
    }

    React.useEffect(()=>{
        init();
    },[])

    const renderItem=(item:any)=>{
        return(
            <List.Item>
                <Card className='card'>
                    <Statistic
                        title={item.title}
                        value={item.value}
                        prefix={
                            <i style={{color:item.color}} className={item.icon} ></i>
                        }
                    />
                </Card>
            </List.Item>
        )
    }

    return(
        <div>
            <List
                dataSource={items}
                renderItem={renderItem}
                grid={{
                    gutter:8, lg:4, md:4, xl: 4, xxl: 4
                }}
            />
        </div>
    )
}