import { Table, Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { upper } from "../../utils/text";

interface otherInterface {
  items: any[];
}

const { Text } = Typography;

export function OtherSubjects(props: otherInterface) {
  return (
    <Table
      dataSource={props.items}
      columns={[
        {
          title: "Titre",
          dataIndex: "title",
          align: "center",
          render(value, record, index) {
            return <div>{upper(value)}</div>;
          },
        },
        {
          title: "Leçons",
          dataIndex: "lessons",
          align: "center",
          render(value, record, index) {
            return (
              <Text
                style={{
                  display: "inline-block",
                  textAlign: "center",
                  width: "100%",
                }}
                strong
                type="secondary"
              >
                {value?.length}
              </Text>
            );
          },
        },
        {
          title: "Chapitres",
          dataIndex: "chapiters",
          align: "center",
          render(value, record, index) {
            return (
              <Text
                style={{
                  display: "inline-block",
                  textAlign: "center",
                  width: "100%",
                }}
                strong
                type="secondary"
              >
                {value?.length}
              </Text>
            );
          },
        },
        {
          title: "Sous-branches",
          dataIndex: "branchs",
          align: "center",
          render(value, record, index) {
            return (
              <Text
                style={{
                  display: "inline-block",
                  textAlign: "center",
                  width: "100%",
                }}
                strong
                type="secondary"
              >
                {value?.length}
              </Text>
            );
          },
        },
        {
          title: "Previsions",
          dataIndex: "forecats",
          align: "center",
          render(value, record, index) {
            return (
              <Text
                style={{
                  display: "inline-block",
                  textAlign: "center",
                  width: "100%",
                }}
                strong
                type="secondary"
              >
                {value?.length}
              </Text>
            );
          },
        },
      ]}
    />
  );
}
