import {
  BookFilled,
  BranchesOutlined,
  DesktopOutlined,
  HomeFilled,
  ReadOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { DashboardScreen } from "./screens/dashboard";
import { EstablishmentScreen } from "./screens/ets";
import { LevelView } from "./screens/level";
import { OrganizationScreen } from "./screens/organization";
import { SchoolScreen } from "./screens/school";
import { StructureView } from "./screens/struct";
import { SubjectScreen } from "./screens/subject";
import { TeacherScreen } from "./screens/teacher";
import { UniversityScreen } from "./screens/university";
import { ForecastView } from "./screens/forecastView";
import { LoginScreen } from "./screens/login";
import { LessonStudioScreen } from "./screens/lessonStudio";
import { RegisterScreen } from "./screens/register";
import { TeacherView } from "./screens/teacher/view";
import { ForecatMonth } from "./screens/forecatMonth";
import { ForecastSubject } from "./screens/subject/forecast";
import { PaymentScreen } from './screens/subscription/payments';
import { forecatRoutes } from "./routes/forecats";
import { structureRoutes } from "./routes/structurre";
import { SubjectAllScreen } from "./screens/subject/all";
import { StudentAllScreen } from "./screens/students";

export declare type userType = "student" | "responsible" | "teacher";

export interface routeInterface {
  component?: any;
  icon?: any;
  layout: "/admin" | "/home";
  menu: boolean;
  label: string;
  name: string;
  path: string;
  font?: string | null;
  group?: string;
  active?: boolean;
  parent?: string;
}

const mainRoutes: routeInterface[] = [
  {
    component: DashboardScreen,
    icon: HomeFilled,
    layout: "/admin",
    menu: true,
    label: "Tableau de bord",
    name: "homeScreen",
    path: "/",
    font: "fa-solid fa-gauge-circle-bolt",
  },
  {
    component: TeacherScreen,
    icon: HomeFilled,
    layout: "/admin",
    menu: true,
    label: "Enseignants",
    name: "TeacherScreen",
    path: "/teachers",
    font: "fa-duotone fa-chalkboard-user",
  },
  {
    component: EstablishmentScreen,
    icon: BranchesOutlined,
    layout: "/admin",
    menu: true,
    label: "Etablissements",
    name: "agencyScreen",
    path: "/establishments",
    font: "fa-solid fa-school",
  },
  {
    component: LoginScreen,
    icon: null,
    layout: "/home",
    menu: true,
    label: "login",
    name: "loginScreen",
    path: "/login",
    font: null,
  },
  {
    component: null,
    icon: null,
    layout: "/home",
    menu: true,
    label: "inscription",
    name: "authScreen",
    path: "/register",
    font: null,
  },
  {
    component: null,
    icon: null,
    layout: "/admin",
    menu: false,
    label: "Dossiers",
    name: "folderScreen",
    path: "/folder/:uid/view",
    font: null,
  },
  {
    component: null,
    icon: null,
    layout: "/admin",
    menu: false,
    label: "Paramètres",
    name: "settingScreen",
    path: "/setting",
    font: null,
  },
  {
    component: null,
    icon: null,
    layout: "/admin",
    menu: false,
    label: "profil",
    name: "profilScreen",
    path: "/profil",
    font: null,
  },
  {
    label: "Agents",
    layout: "/admin",
    menu: true,
    name: "RegisterScreen",
    path: "/register",
    active: true,
    component: RegisterScreen,
    font: "fa-duotone fa-users",
  },
  {
    label: "teacher",
    layout: "/admin",
    menu: false,
    name: "TeacherView",
    path: "/teacher/:id/view",
    active: true,
    component: TeacherView,
  },
  {
    font: "fa-solid fa-money-bills-simple",
    label: "Paiements",
    name: "payment",
    path: "#",
    layout: "/admin",
    menu: true,
    active: true,
    group: "payment",
  },
  {
    component: PaymentScreen,
    icon: TeamOutlined,
    layout: "/admin",
    menu: true,
    label: "Tous les paiements",
    name: "paymentScreen",
    path: "/payments",
    font: "fa-solid fa-money-check-dollar-pen",
    parent: "payment",
  },
  {
    component: SubjectAllScreen,
    icon: ReadOutlined,
    layout: "/admin",
    menu: true,
    label: "Tous les cours",
    name: "allCourseScreen",
    path: "/courses",
    font: "fa-regular fa-book-open",
  },
  {
    component: StudentAllScreen,
    icon: DesktopOutlined,
    layout: "/admin",
    menu: true,
    label: "Tous les étudiants",
    name: "allStudentScreen",
    path: "/students",
    font: "fa-solid fa-graduation-cap",
  },
];

const routes:routeInterface[] = [];


function addRoutes(...list: routeInterface[][]) {
  for (let items of list) {
    items.map((element) => {
      const index = routes.findIndex((p) => p.name === element.name);
      if (index === -1) {
        routes.push(element);
      }
    });
  }
}

addRoutes(
  mainRoutes,
  forecatRoutes,
  structureRoutes,
)

function getRouteByPath(layout:"/admin" | "/home",path:string){
  const url = `${layout}/${path}`;
  
}

function isCurrentUrl(path: string,current:string) {
  return path === current || path === current + "/";
}

export function getRoutesForBreadcrumb(){
  const elements:any[] = [];
  routes.forEach(p=>{
    const item:any = {...p};
    item.breadcrumb = item.label;
    item.path = `${item.layout}${item.path}`;
    elements.push(item);
  })
  return elements;
}

export default routes;
