import axios from "axios";
import { isEmpty } from "lodash";
import { getToken, tokenKey, yecKey } from "../utils/helpers/title";
//import userHelper from "../utils/helpers/userHelper";
//import authServiceApi from "./authService";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import elem from "./config";
import { Modal, notification } from "antd";
import userHelper from "../utils/helpers/userHelper";

const http = axios.create({
  baseURL: process.env.REACT_APP_ENV=='int'?process.env.REACT_APP_URL_API:process.env.REACT_APP_URL_API_PROD,
  headers: {
    "Content-Type": "application/json",
  },
});

const CancelToken = axios.CancelToken;

const setAuthHeader = (config: any) => {
  const token = getToken();
  if (!isEmpty(token)) {
    config.headers["Authorization"] = "Bearer " + token;
  } else {
    config.cancelToken = new CancelToken((cancel) => cancel("No token"));
  }
  return config;
};

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      Modal.warn({
        centered: true,
        title: "La session est expirée",
        content: "Votre session est expirée, veuillez vous reconnecter",
        okText: "Connexion",
        onOk: () => {
          localStorage.removeItem(yecKey);
          localStorage.removeItem(tokenKey);
          window.location.href = "/home/login";
        },
      });
    }
  }
);

http.interceptors.request.use(setAuthHeader);

/*createAuthRefreshInterceptor(http, (failedRequest) =>{}

)*/

/*createAuthRefreshInterceptor(http, (failedRequest) =>{}
    authServiceApi.refreshToken().then((result) => {
        if (result === true) {
            setAuthHeader(failedRequest.response.config);
            return Promise.resolve();
        }
        const refreshToken = authServiceApi.getRefreshToken();
        if (refreshToken) {
            message.error('Votre session a expiré, veuillez vous reconnecter');
        }
        const disconnectElem = document.querySelector('.disconnect');
        if (disconnectElem) {
            disconnectElem.click();
        }
        return Promise.reject(`${refreshToken ? 'Expired' : 'No'} refresh token`);
    }),
);*/

export default http;
