import { EyeFilled, EyeOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table, Typography } from "antd";
import React from "react";


export function ForecastViewList(props:{data:any[]}){

    return(
        <>
        <Table 
            className="table__1" dataSource={props.data}
            columns={[
                {title:"Titre",dataIndex:"title",defaultSortOrder: 'ascend',
                sorter: (a, b) => a?.title?.localeCompare(b?.title)},
                {title:"Matière",dataIndex:"subject",render(value, record, index) {
                    return value?.title
                },},
                {
                    title:"Classe",dataIndex:"subject",render(value, record, index) {
                        return value?.level?.title
                    },
                },
                {
                    title:"Branche",dataIndex:"branch",render(value, record, index) {
                        return value?.title
                    },
                },
                {
                    title:"Resumé",dataIndex:"summary",render(value, record, index) {
                        return(
                            <Typography.Paragraph ellipsis={{rows:3}}>
                                {value}
                            </Typography.Paragraph>
                        )
                    },
                },
                {
                    render(value, record, index) {
                        return(
                            <Row>
                                <Col>
                                <Button shape="circle">
                                    <EyeOutlined rev="" />
                                </Button>
                                </Col>
                            </Row>
                        )
                    },
                }
            ]}
        />
        </>
    )
}
