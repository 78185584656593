import { Table, Typography } from "antd";
import React from "react";
import { useDrop, useDrag, DndProvider } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { setChapiters } from "../../redux/chapiterReducer";
import { RootState } from "../../redux/store";
import update from "immutability-helper";
import { HTML5Backend } from "react-dnd-html5-backend";
import chapiterService from "../../services/chapiterService";
import { getUid } from "../../utils/helpers/title";

interface DraggableBodyRowProps
  extends React.HTMLAttributes<HTMLTableRowElement> {
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

const type = "DraggableBodyRow";

const DraggableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}: DraggableBodyRowProps) => {
  const ref = React.useRef<HTMLTableRowElement>(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item: { index: number }) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
};

export function SubjectChapters() {
  const chapters = useSelector((state: RootState) => state.chapter.list);
  const dispatch = useDispatch();
  const [updated, setUpdated] = React.useState(false);

  async function updateChapters() {
    chapters.forEach((p) => {
      const index = chapters.findIndex((x) => p["@id"] === x["@id"]);
      if (index !== -1) {
        chapiterService.update(getUid(p["@id"]), { priority: index });
      }
    });
  }

  React.useEffect(() => {
    setUpdated(true);
    if (updated) {
      updateChapters();
    }
  }, [chapters]);

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = chapters[dragIndex];
      dispatch(
        setChapiters(
          update(chapters, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragRow],
            ],
          })
        )
      );
    },
    [chapters]
  );

  return (
    <React.Fragment>
      <DndProvider backend={HTML5Backend}>
        <Table
          columns={[
            { title: "Titre", dataIndex: "title" },
            {
              title: "Nombre de leçons",
              width: 150,
              dataIndex: "lessons",
              render(value, record, index) {
                return (
                  <Typography.Text
                    type="secondary"
                    strong
                    style={{
                      display: "inline-block",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    {value?.length}
                  </Typography.Text>
                );
              },
            },
          ]}
          dataSource={chapters}
          components={components}
          onRow={(_, index) => {
            const attr = {
              index,
              moveRow,
            };
            return attr as React.HTMLAttributes<any>;
          }}
        />
      </DndProvider>
    </React.Fragment>
  );
}
