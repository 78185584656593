import { setProfilReadings } from "../../redux/readingReducer";
import readingService from "../../services/readingService";
import { isEmpty } from "lodash";
import { getUid, tokenKey, yecKey } from "./title";
import { userType } from "../../routes";
import userService from "../../services/userService";
import dateHelpers from "./dateHelpers";
import { setUserProfil } from "../../redux/userReducer";

class UserHelper {
  setToken(token: string) {
    localStorage.setItem(tokenKey, token);
  }

  getData(): any | null {
    let response: any = null;
    const item = localStorage.getItem(yecKey);
    if (typeof item === "string") {
      response = JSON.parse(item);
    }
    return response;
  }

  isConnected() {
    const user = this.getData();
    return user !== null;
  }

  async refreshUser() {
    let value = false;
    const user = this.getData();
    if (user !== null) {
      const code = user.code;
      await userService
        .login({ username: code, password: code })
        .then((response) => {
          const data = response.data;
          this.setToken(data.token);
          value = true;
        })
        .catch((reason) => {
          console.warn(reason);
        });
    }
    return value;
  }

  isStudent(user: any): boolean {
    return !isEmpty(user.student);
  }
  isAdmin(user: any): boolean {
    return !isEmpty(user.admin);
  }
  isResponsible(user: any): boolean {
    return !isEmpty(user.responsible);
  }
  isTeacher(user: any): boolean {
    return !isEmpty(user.teacher);
  }
  getResponsible(user: any): any | null {
    if (!this.isResponsible(user)) {
      return null;
    }
    return user.responsible;
  }

  getStudent(user: any): any | null {
    if (!this.isStudent(user)) {
      return null;
    }
    return user.student;
  }

  getAdmin(user: any): any | null {
    if (!this.isAdmin(user)) {
      return null;
    }
    return user.admin;
  }

  getTeacher(user: any): any | null {
    if (!this.isTeacher(user)) {
      return null;
    }
    return user.teacher;
  }

  isVisible(
    values: userType[],
    user: any,
    subscribeable: boolean,
    subscriptionValid: boolean
  ) {
    let response = false;
    if (
      values.findIndex((p) => p === "responsible") != -1 &&
      this.isResponsible(user)
    ) {
      response = true;
    } else if (
      values.findIndex((p) => p === "student") != -1 &&
      this.isStudent(user)
    ) {
      response = true;
    } else if (
      values.findIndex((p) => p == "teacher") != -1 &&
      this.isTeacher(user)
    ) {
      response = true;
    }
    if (subscribeable && !subscriptionValid) {
      response = false;
    }
    return response;
  }

  setData(item: object, dispatch: any) {
    if (typeof dispatch === "function") {
      dispatch(setUserProfil(item));
    }
    localStorage.setItem(yecKey, JSON.stringify(item));
  }

  setLogout(dispatch: any) {
    if (typeof dispatch === "function") {
      dispatch(setUserProfil({}));
    }
    localStorage.removeItem(yecKey);
    localStorage.removeItem(tokenKey);
  }

  async setReading(dispatch: any) {
    const user = this.getData();
    if (typeof dispatch === "function" && !isEmpty(user)) {
      if (isEmpty(user.student)) {
        return;
      }
      await readingService
        .getByKey(
          `student=${getUid(user.student["@id"])}&order[createdAt]=desc`
        )
        .then((response) => {
          const data = readingService.getData(response);
          dispatch(setProfilReadings(data));
        })
        .catch((reason) => {
          console.warn(reason);
        });
    }
  }

  getSubscriptionValidation(subscription: any) {
    let value = "L'abonnement n'est pas valide";
    const { now, validatedAt } = subscription;
    if (!isEmpty(now) && !isEmpty(validatedAt)) {
      const days = dateHelpers.getDifference(validatedAt, now, "days");
      if (days > 1) {
        value = `Reste ${days} jours d'abonnement`;
      } else if (days === 0) {
        value = `Le dernier jour d'abonnement`;
      } else if (days === 1) {
        value = `Valide jusqu'à demain à ${dateHelpers.getDate(
          validatedAt,
          "HH[h]mm"
        )}`;
      }
    }
    return value;
  }
}

export default new UserHelper();
