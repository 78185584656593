import React, { PropsWithChildren } from 'react';
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Pie, ChartProps } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const PieChart:React.FC<PropsWithChildren<{
    data:any[];labels:any[];title:string;color?:string[];
}>>=({data, labels, title, color})=>{

    return(
        <React.Fragment>
            <Pie
                data={{
                    datasets:[
                        {
                            data:data,
                            label:title,
                            backgroundColor:color,
                        }
                    ],
                    labels:labels
                }}
                title={title}
            />
        </React.Fragment>
    )
}