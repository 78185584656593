import {
  Avatar,
  Card,
  Col,
  Layout,
  List,
  Row,
  Segmented,
  Skeleton,
  Table,
  Tag,
  Typography,
} from "antd";
import { isEmpty, toNumber, uniqueId, upperCase } from "lodash";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { setLocale } from "yup";
import Calendar from "../components/calendar";
import { MonthCard } from "../components/card/month";
import forecastService from "../services/forecastService";
import subjectService from "../services/subjectService";
import dateHelpers from "../utils/helpers/dateHelpers";
import { CreateForecast } from "./forecast/create";
import { DlSelect } from "../components/input";
import { useFormik } from "formik";
import { BreadcrumbComponent } from "../components/breadcrumb";
import { ForecastViewList } from "./forecast/list";

export function ForecastView() {
  const [item, setItem] = React.useState<any>({});
  const [visible, setVisible] = React.useState(false);
  const [loading, setloading] = React.useState(true);
  const [subject, setSubject] = React.useState<any>({});
  const [items, setItems] = React.useState<any[]>([]);
  const [data, setData] = React.useState<any[]>([]);
  const [scolarYears, setScolarYears] = React.useState<any[]>([]);
  const [month, setMonth] = React.useState(0);
  const [segment, setSegment] = React.useState<any>("list");
  const params = useParams();

  const formik = useFormik({
    initialValues:{
      year:"",
    },
    onSubmit:()=>{}
  });

  async function init(item:any) {
    const date = new Date();
    const elements: any[] = [];
    const {year1,year2} = item;
    setloading(true);
    for (let index = 9; index <= 12; index++) {
      const count = await getForecats(index,year1);
      const d = new Date(year1, index - 1);
      elements.push({
        month: dateHelpers.getDate(d, "MMMM YYYY"),
        year: year1,
        count: count,
        index: index,
      });
    }
    for (let index = 1; index <= 8; index++) {
      const count = await getForecats(index,year2);
      const d = new Date(year2, index - 1);
      elements.push({
        month: dateHelpers.getDate(d, "MMMM YYYY"),
        year: year2,
        count: count,
        index: index,
      });
    }
    setItems(elements);
    setloading(false);
  }

  async function getForecats(m: number,year:number) {
    let count = 0;
    await forecastService
      .getByKey(`month=${m}&year=${year}`)
      .then((response) => {
        count = forecastService.getTotal(response);
      })
      .catch(() => {});
    return count;
  }

  async function getAllForecats() {
    let count = 0;
    await forecastService
      .getByKey(``)
      .then((response) => {
        setData(forecastService.getData(response))
      })
      .catch(() => {});
    return count;
  }

  React.useEffect(() => {
    //init();
    initScolarYears();
  }, [params]);

  function initScolarYears(){
    const date = new Date();
    const year = toNumber(dateHelpers.getDate(date, "YYYY"));
    const m = toNumber(dateHelpers.getDate(date, "MM"));
    const elements:any[] = [];
    const uid = `${uniqueId("hjkl")}`;
    let year1 = 0;
    let year2 = 0;
    if(m>=8){
      year1 = year;
      year2 = year+1;
    }else{
      year1=year-1;
      year2=year;
    }
    elements.push({
      year1:year1-1,
      year2:year2-1,
      title:`${year1-1}-${year2-1}`,
      value:`${uniqueId("calendar")}`,
    });
    elements.push({
      year1,
      year2,
      title:`${year1}-${year2}`,
      value:`${uid}`,
    });
    // elements.push({
    //   year1:year1+1,
    //   year2:year2+1,
    //   title:`${year1+1}-${year2+1}`,
    //   uid:uniqueId(),
    // });
    setScolarYears(elements);
    formik.setFieldValue("year",uid);
    // setYear(toNumber(dateHelpers.getDate(date, "YYYY")));
    // setMonth(toNumber(dateHelpers.getDate(date, "MM")));
  }

  function handleClickWeek() {}

  React.useEffect(() => {
    if (item.week > 0 && !isEmpty(subject.uid)) {
      setVisible(true);
    }
  }, [item]);

  React.useEffect(()=>{
    onInit();
  },[formik.values.year])

  const onInit = React.useCallback(async()=>{
    getAllForecats();
    const v = formik.values.year;
    if(!isEmpty(v)){
      const item = scolarYears.find(p=>p.value===v);
      init(item);
    }
  },[formik.values.year])

  const renderItem = (p: any, index: number) => {
    return (
      <List.Item style={{ cursor: "pointer" }}>
        <MonthCard
          count={p.count}
          index={p.index}
          title={p.month}
          year={p.year}
          key={`card_month_${index}`}
        />
      </List.Item>
    );
  };

  return (
    <React.Fragment>
      <BreadcrumbComponent title="Toutes les prévisions">
      </BreadcrumbComponent>
      <Segmented
        options={[
          {value:"list",label:"Liste"},
          {value:"calendar",label:"Calendrier"},
        ]}
        style={{marginBottom:12}}
        onChange={v=>setSegment(v)}
      />
      <Layout className="box">
        <div style={{padding:"15px 0"}}>
          <div style={{maxWidth:200}}>
          <DlSelect label="Année scolaire" formik={formik} list={scolarYears} name="year" />
          </div>
        </div>
        {!loading && (
          <div>
            {segment==="calendar" && (<List
            dataSource={items}
            renderItem={renderItem}
            grid={{ lg: 4, xl: 4, xxl: 4, gutter: 16 }}
          />)}
          {segment === "list" && (
            <ForecastViewList data={data} />
          )}
          </div>
        )}
        {loading && (
          <React.Fragment>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </React.Fragment>
        )}
      </Layout>
      {/* <CreateForecast
        setVisible={setVisible}
        visible={visible}
        subject={params.id}
        item={item}
        init={getForecats}
      /> */}
    </React.Fragment>
  );
}
