import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface etsData {
  current: any;
  list: any[];
  filters: any[];
  itemLength: number;
}

const initialState: etsData = {
  current: {},
  list: [],
  filters: [],
  itemLength: 0,
};

export const etsablishmentSlice = createSlice({
  name: "ets",
  initialState,
  reducers: {
    setCurrentEstablishement: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setEstablishments: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setEstablishmentFilters: (state, action: PayloadAction<any[]>) => {
      state.filters = action.payload;
    },
    setEstablishmentCount: (state, action: PayloadAction<number>) => {
      state.itemLength = action.payload;
    },
  },
});
export const {
  setCurrentEstablishement,
  setEstablishments,
  setEstablishmentFilters,
  setEstablishmentCount,
} = etsablishmentSlice.actions;
export default etsablishmentSlice.reducer;
