import React from "react";
import { useParams } from "react-router-dom";
import { isArray, isEmpty, upperFirst } from "lodash";
import groupService from "../services/groupService";
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Layout,
  Modal,
  notification,
  Row,
  Skeleton,
  Tag,
  Tree,
  Typography,
} from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import { getUid, upper } from "../utils/text";
import { EventDataNode } from "antd/lib/tree";
import { ShowingStruct } from "./structure/showing";
import { getGroupTitle } from "../utils/helpers/title";
import { CreateGroup } from "./group/create";
import levelService from "../services/levelService";

/*declare type expandType = {
  node: EventDataNode;
  expanded: boolean;
  nativeEvent: MouseEvent;
};*/

export function StructureView() {
  const params = useParams();
  const [group, setGroup] = React.useState<any>({});
  const [current, setCurrent] = React.useState<any>({});
  const [loading, setLoading] = React.useState(false);
  const [treeNode, setTreeNode] = React.useState<any[]>([]);
  const [waiting, setWaiting] = React.useState(false);
  const [levels, setLevels] = React.useState<any[]>([]);
  const [count, setCount] = React.useState<number>(0);

  const [expandedKeys, setExpandedKeys] = React.useState<React.Key[]>([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [autoExpandParent, setAutoExpandParent] = React.useState(true);

  const onExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  React.useEffect(() => {
    init();
  }, [params]);

  React.useEffect(() => {
    const data = getChildren(group) as any[];
    setTreeNode(data);
  }, [group]);

  async function getLevels(uid: string) {
    await levelService
      .getByKey(`groups=${uid}&limit=1000&order[promotion]=asc`)
      .then((response) => {
        const data = levelService.getData(response);
        const count = levelService.getTotal(response);
        setLevels(data);
        setCount(count);
      })
      .catch(() => {});
  }

  function organizeTree(item: any) {
    const children = getChildren(item);
    const data = {
      key: getUid(item["@id"]),
      title: item.name,
      icon: <i className="fa-duotone fa-backpack"></i>,
      children,
    };
    return data;
  }

  function getChildren(item: any) {
    if (!isArray(item?.children)) {
      return [];
    }
    if (item.children.length > 0) {
      const response: any[] = [];
      item.children.forEach((elem: any) => {
        response.push(organizeTree(elem));
      });
      return response;
    }
    return [];
  }

  async function init() {
    const uid = params?.id as string;
    if (isEmpty(uid)) {
      notification.error({
        message: "Affichage des données",
        description: "Cette demande n'est pas correcte!",
      });
      window.history.back();
      return;
    }
    setLoading(true);
    const data = await getGroup(uid);
    await getLevels(uid);
    setGroup(data);
    setLoading(false);
  }

  async function getGroup(uid: string) {
    let rep = {};
    await groupService
      .get(uid)
      .then((response) => {
        rep = response.data;
      })
      .catch(() => {});
    return rep;
  }

  async function handleClick(item: any[]) {
    const uid = item[0];
    setWaiting(true);
    const data = await getGroup(uid);
    setCurrent(data);
    setWaiting(false);
  }

  async function handleRemove() {
    Modal.warning({
      title: "Suppression de groupe",
      content: "Souhaitez-vous supprimer définitivement ce groupe?",
      okText: "Supprimer",
      centered: true,
      onCancel: () => {
        console.log("dds");
      },
      cancelText: "Annuler",
      okCancel: true,
      okType: "primary",
      okButtonProps: { danger: true, loading: loading, disabled: loading },
      cancelButtonProps: { hidden: false },
      onOk: async () => {
        setLoading(true);
        await groupService
          .destroy(group.uid)
          .then(() => {
            window.history.back();
          })
          .catch(() => {});
        setLoading(false);
      },
    });
  }

  return (
    <React.Fragment>
      <Layout>
        {loading && (
          <React.Fragment>
            <Skeleton.Button
              active
              style={{ height: "20vh", width: "100%", marginBottom: 5 }}
            />
            <Row gutter={4} align="stretch" style={{ width: "100%" }}>
              <Col span={8}>
                <Skeleton.Button
                  size="large"
                  block
                  style={{ height: "60vh", width: "100%" }}
                  active
                />
              </Col>
              <Col span={16}>
                <Skeleton active />
              </Col>
            </Row>
          </React.Fragment>
        )}
        {!loading && (
          <React.Fragment>
            <PageHeader
              onBack={() => window.history.back()}
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <i className="fa-duotone fa-backpack"></i>
                  <h1
                    style={{
                      padding: "0 10px",
                      display: "inline-block",
                      margin: 0,
                    }}
                    className="yc_h1"
                  >
                    {group.name}
                  </h1>
                </div>
              }
              subTitle={upper(getGroupTitle(group))}
              extra={[
                <CreateGroup
                  label={
                    group?.type === "cycle"
                      ? "Ajouter une section"
                      : group?.type === "faculty"
                      ? "Ajouter un département"
                      : ""
                  }
                  type={group.type}
                  init={init}
                  id={group.uid}
                />,
                levels.length === 0 && group.children?.length === 0 && (
                  <Button danger type="primary" onClick={handleRemove}>
                    {`Supprimer`}
                  </Button>
                ),
              ]}
            >
              <Descriptions column={2}>
                <Descriptions.Item label="Type de groupement">
                  {upper(getGroupTitle(group))}
                </Descriptions.Item>
                <Descriptions.Item
                  style={{ display: "block" }}
                  contentStyle={{ display: "block", width: "100%" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {levels.map((p: any, index: number) => (
                      <Tag key={`tag_${index}`}>{p.title}</Tag>
                    ))}
                  </div>
                </Descriptions.Item>
                <Descriptions.Item label="Nombre de niveaux">
                  {count}
                </Descriptions.Item>
              </Descriptions>
            </PageHeader>
            <Divider style={{ marginBottom: 0 }} />
            <Row gutter={8}>
              <Col
                span={6}
                style={{
                  borderLeft: "solid .5px #f1f1f144",
                  minHeight: "70vh",
                  background: "#0001",
                }}
              >
                <Typography.Title
                  className="yc_h2"
                  level={2}
                  style={{ textAlign: "center", padding: "10px 5px" }}
                >
                  {`Organisation de ${getGroupTitle(group)}`}
                </Typography.Title>
                <Divider style={{ margin: 0 }} />
                <Tree
                  treeData={treeNode}
                  onExpand={(expandedKeys) => onExpand(expandedKeys)}
                  expandedKeys={expandedKeys}
                  autoExpandParent={autoExpandParent}
                  onSelect={(e) => handleClick(e)}
                  disabled={waiting}
                  showIcon
                  style={{ animation: "ease-in", background: "transparent" }}
                  rootStyle={{ background: "transparent", padding: "15px 0" }}
                  titleRender={(value: any) => {
                    return (
                      <span
                        style={{ fontSize: 16 }}
                        className={`${
                          value.key === getUid(current["@id"])
                            ? "yc_text_primary"
                            : ""
                        }`}
                      >
                        {upperFirst(value?.title)}
                      </span>
                    );
                  }}
                />
              </Col>
              <Col flex={1}>
                <ShowingStruct
                  item={current}
                  parent={group}
                  setCurrentGroup={setGroup}
                />
              </Col>
            </Row>
          </React.Fragment>
        )}
      </Layout>
    </React.Fragment>
  );
}
