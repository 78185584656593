import {
  Avatar,
  Card,
  Col,
  Layout,
  List,
  Row,
  Skeleton,
  Tag,
  Typography,
} from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import { isEmpty, toNumber, upperCase } from "lodash";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { MonthCard } from "../../components/card/month";
import forecastService from "../../services/forecastService";
import subjectService from "../../services/subjectService";
import dateHelpers from "../../utils/helpers/dateHelpers";
import { CreateForecast } from "../forecast/create";
import { BreadcrumbComponent } from "../../components/breadcrumb";

export function ForecastSubject() {
  const [item, setItem] = React.useState<any>({});
  const [visible, setVisible] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  const [subject, setSubject] = React.useState<any>({});
  const [items, setItems] = React.useState<any[]>([]);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [month, setMonth] = React.useState(0);
  const params = useParams();

  async function init() {
    const date = new Date();
    const elements: any[] = [];
    const uid = params.id as string;
    setYear(toNumber(dateHelpers.getDate(date, "YYYY")));
    setMonth(toNumber(dateHelpers.getDate(date, "MM")));
    setloading(true);
    for (let index = 1; index <= 12; index++) {
      const count = await getForecats(index, uid);
      const d = new Date(year, index - 1);
      elements.push({
        month: dateHelpers.getDate(d, "MMMM YYYY"),
        year: year,
        count: count,
        index: index,
      });
    }
    setItems(elements);
    setloading(false);
  }

  async function getForecats(m: number, uid: string) {
    let count = 0;
    await forecastService
      .getByKey(`month=${m}&year=${year}&subject=${uid}`)
      .then((response) => {
        count = forecastService.getTotal(response);
      })
      .catch(() => {});
    return count;
  }

  async function getSubject() {
    let count = 0;
    await subjectService
      .get(`${params.id}`)
      .then((response) => {
        setSubject(response.data);
      })
      .catch(() => {});
    return count;
  }

  React.useEffect(() => {
    onInit();
  }, [params, year]);

  const onInit = React.useCallback(()=>{
    getSubject();
    init();
  },[params, year])

  function handleClickWeek() {}

  React.useEffect(() => {
    if (item.week > 0 && !isEmpty(subject.uid)) {
      setVisible(true);
    }
  }, [item]);

  const renderItem = (p: any, index: number) => {
    return (
      <List.Item style={{ cursor: "pointer" }}>
        <Link
          to={`/admin/forecasts/month?subject=${params.id}&month=${p.index}&year=${year}`}
        >
          <MonthCard
            count={p.count}
            index={p.index}
            title={p.month}
            year={year}
            key={`card_month_${index}`}
          />
        </Link>
      </List.Item>
    );
  };

  return (
    <React.Fragment>
      <BreadcrumbComponent title="Les prévisions du cours">
        <div>
          <Typography.Text>
            {`TITRE DU COURS`}
          </Typography.Text>
          {!isEmpty(subject) && (<div>
          <Typography.Text>
            <Link to={`/admin/subjects/${params.id}/view`}>
            {subject?.title}
            </Link>
          </Typography.Text>
          </div>)}
        </div>
      </BreadcrumbComponent>
      <Layout className="box">
        {!loading && (
          <List
            dataSource={items}
            renderItem={renderItem}
            grid={{ lg: 4, xl: 4, xxl: 4, gutter: 16 }}
          />
        )}
        {loading && (
          <React.Fragment>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </React.Fragment>
        )}
      </Layout>
    </React.Fragment>
  );
}
