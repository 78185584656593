import {
  FileImageFilled,
  InboxOutlined,
  VideoCameraAddOutlined,
} from "@ant-design/icons";
import { Col, Modal, notification, Progress, Row, Spin, Table, Upload } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { isEmpty, toNumber } from "lodash";
import { Player } from "video-react";
import React from "react";
import "video-react/dist/video-react.css";
import mediaHelper from "../../utils/helpers/mediaHelper";

interface videoInterface {
  file: any | null;
  setFile: any;
  thumb: any | null;
  setThumb: any;
}

export function VideoLesson(props: videoInterface) {
  const { file, setFile, setThumb, thumb } = props;
  const [items, setItems] = React.useState<any[]>([]);
  const [image, setImage] = React.useState<any | undefined>();
  const [video, setVideo] = React.useState<any | undefined>();
  const [uploading, setUploading] = React.useState(false);
  const [uploadPercent, setUploadPercent] = React.useState(0);
  const [thumbFile, setThumbFile] = React.useState<any>({});
  const player = React.useRef();

  async function onChange(info: UploadChangeParam<UploadFile<any>>) {
    const { status,percent } = info.file;
    setUploading(status==="uploading");
    setUploadPercent(percent || 0);
    console.log("INFO",info.file);
    console.log("INFO EVENT",info.event?.percent);
    if (status !== "uploading" && typeof setFile === "function") {
      const size = info.file.size;
      const type = info.file.type;
      if (!type?.includes("video")) {
        notification.warning({
          message: "Type de fichier",
          description: "Veuillez ajouter une vidéo",
        });
        return;
      }
      if (toNumber(size) > 10 * 1024 * 1024) {
        notification.warning({
          message: "Taille du fihier",
          description: "La taille du fichier ne peut pas dépasser 10Mo",
        });
        return;
      }
      setFile(info.file);
      const url = await mediaHelper.getBase64(
        info.file.originFileObj as RcFile
      );
      setVideo(url);
    }
  }
  function onDrop(e: any) {
    console.log("Dropped files", e.dataTransfer.files);
  }

  async function onChangePoster(info: UploadChangeParam<UploadFile<any>>) {
    const { status } = info.file;
    if (status !== "uploading" && typeof setThumb === "function") {
      const size = info.file.size;
      const type = info.file.type;
      if (!type?.includes("image")) {
        notification.warning({
          message: "Type de fichier",
          description: "Veuillez ajouter une image",
        });
        return;
      }
      if (toNumber(size) > 1 * 1024 * 1024) {
        notification.warning({
          message: "Taille du fihier",
          description: "La taille du fichier ne peut pas dépasser 1Mo",
        });
        return;
      }
      setThumb(info.file);
      setThumbFile(info.file);
      const url = await mediaHelper.getBase64(
        info.file.originFileObj as RcFile
      );
      setImage(url);
    }
  }
  function onDropPoster(e: any) {
    console.log("Dropped files", e.dataTransfer.files);
  }

  async function loadFile() {
    if (!isEmpty(file)) {
      const url = await mediaHelper.getBase64(file.originFileObj as RcFile);
      setVideo(url);
    }
  }

  async function loadThumb() {
    if (!isEmpty(thumbFile)) {
      const url = await mediaHelper.getBase64(thumbFile.originFileObj as RcFile);
      setThumb(url);
    }
  }

  React.useEffect(() => {
    loadFile();
  }, [file]);

  React.useEffect(() => {
    loadThumb();
  }, [thumbFile]);

  React.useEffect(() => {
    const elements: any[] = [];
    if (!isEmpty(file)) {
      elements.push({ name: "Nom de la vidéo", value: file.name });
    }
    if (!isEmpty(thumb)) {
      elements.push({ name: "Nom de l'image", value: thumbFile?.name });
    }
    setItems(elements);
  }, [file, thumb,thumbFile]);

  async function uploadVideo(file: RcFile){
    return ""
  }

  return (
    <React.Fragment>
      <Row gutter={8} wrap={false} align="top" style={{ maxHeight: "100vh" }}>
        <Col span={6}>
          <div>
            <Upload.Dragger
              multiple={false}
              accept="video/*"
              name="file-video"
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              //listType=""
              onChange={onChange}
              showUploadList={false}
              onDrop={onDrop}
              defaultFileList={isEmpty(file) ? [] : [file]}
              // fileList={isEmpty(file) ? [] : [file]}
              // action={uploadVideo}
              // method="POST"
            >
              <p className="ant-upload-drag-icon">
                <VideoCameraAddOutlined rev="" />
              </p>
              <p className="ant-upload-text">
                {`Cliquez ou faites glisser la vidéo dans cette zone.`}
              </p>
              <p className="ant-upload-hint">
                {`Cette vidéo servira à afficher la leçon`}
              </p>
            </Upload.Dragger>
          </div>
          <div style={{ padding: "10px 0" }}>
            <Upload.Dragger
              multiple={false}
              accept="image/*"
              name="file"
              listType="picture-card"
              onChange={onChangePoster}
              onDrop={onDropPoster}
              showUploadList={false}
              //defaultFileList={isEmpty(thumb) ? [] : [thumb]}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            >
              <p className="ant-upload-drag-icon">
                <FileImageFilled rev="" />
              </p>
              <p className="ant-upload-text">
                {`Cliquez ou faites glisser l'image dans cette zone.`}
              </p>
              <p className="ant-upload-hint">
                {`Cette image servira à afficher la video de la leçon`}
              </p>
            </Upload.Dragger>
          </div>
        </Col>
        <Col flex={1}>
          <div style={{ margin: "auto", position: "relative" }}>
            <Player playsInline poster={image} src={video}></Player>
          </div>
          <Table
            columns={[
              { title: "Désignation", dataIndex: "name" },
              { title: "Valeur", dataIndex: "value" },
            ]}
            dataSource={items}
            pagination={false}
          />
        </Col>
      </Row>
      <Modal 
        open={uploading}
        footer={(<div></div>)}
        cancelButtonProps={{hidden:true}}
        okButtonProps={{hidden:true}}
        centered
        closable={false}
      >
        <div style={{display:"flex",alignItems:"center",justifyContent:"center",minHeight:100,width:"100%",height:"100%"}}>
        {/* <Spin /> */}
        <Progress type="circle" percent={uploadPercent} format={(percent?: number | undefined, successPercent?: number | undefined)=>{
          return percent?.toFixed(2)
        }}>

        </Progress>
        </div>
      </Modal>
    </React.Fragment>
  );
}
