import { Card, Layout, List, Statistic } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

export function StatContent(){

    const dashboard = useSelector((state:RootState)=>state.user.dashboard);
    const [items, setItems] = React.useState<any[]>([]);

    function init(){
        const elements:any[] = [];
        elements.push({
            title: "Promotions",
            value: dashboard.level,
            icon:"fa-solid fa-backpack",
            color:"#098d03",
        });
        elements.push({
            title: "Cours",
            value: dashboard.course,
            icon:"fa-solid fa-planet-ringed",
            color:"#5c987a",
        });
        elements.push({
            title: "Leçons",
            value: dashboard.lesson,
            icon:"fa-solid fa-clipboard-list",
            color:"#faad14",
        });
        elements.push({
            title: "Vidéos",
            value: dashboard.video,
            icon:"fa-solid fa-video",
            color:"#333",
        });
        setItems(elements);
    }

    React.useEffect(()=>{
        init();
    },[])

    const renderItem=(item:any)=>{
        return(
            <List.Item>
                <Card className='card'>
                    <Statistic
                        title={item.title}
                        value={item.value}
                        prefix={
                            <i style={{color:item.color}} className={item.icon} ></i>
                        }
                    />
                </Card>
            </List.Item>
        )
    }

    return(
        <div style={{padding:"10px 0"}}>
            <List
                dataSource={items}
                renderItem={renderItem}
                grid={{
                    gutter:8, lg:4, md:4, xl: 4, xxl: 4
                }}
            />
        </div>
    )
}