import { BookFilled, TeamOutlined } from "@ant-design/icons";
import { routeInterface } from "../routes";
import { OrganizationScreen } from "../screens/organization";
import { LessonStudioScreen } from "../screens/lessonStudio";
import { LevelView } from "../screens/level";
import { SubjectScreen } from "../screens/subject";
import { SchoolScreen } from "../screens/school";
import { UniversityScreen } from "../screens/university";
import { StructureView } from "../screens/struct";
import { SubscriptionScreen } from "../screens/subscription/subscription";

export const structureRoutes:routeInterface[] = [
    {
        component: SubjectScreen,
        icon: BookFilled,
        layout: "/admin",
        menu: false,
        label: "Présentation de cours",
        name: "courseScreen",
        path: "/subjects/:id/view",
        font: null,
      },
      {
        component: LevelView,
        icon: BookFilled,
        layout: "/admin",
        menu: false,
        label: "Classe",
        name: "levelScreen",
        path: "/levels/:id/view",
        font: null,
      },
      {
        component: LessonStudioScreen,
        icon: null,
        layout: "/admin",
        menu: false,
        label: "Créer le cours",
        name: "courseLessonScreen",
        path: "/lessons/:id/create",
        font: null,
      },
      {
        font: "fa-light fa-folder-tree",
        label: "Structure",
        name: "structs",
        path: "#",
        layout: "/admin",
        menu: true,
        active: true,
        group: "structure",
      },
      {
        component: OrganizationScreen,
        icon: TeamOutlined,
        layout: "/admin",
        menu: true,
        label: "Présentation",
        name: "structureSceen",
        path: "/structure",
        font: "fa-duotone fa-chart-network",
        parent: "structure",
      },

  {
    label: "Configuration",
    layout: "/admin",
    menu: false,
    name: "structConfig",
    path: "/struct/:id",
    active: true,
    component: StructureView,
    font: "fa-solid",
    parent: "structure",
  },
  {
    label: "école",
    layout: "/admin",
    menu: true,
    name: "structSchool",
    path: "/struct/school",
    active: true,
    component: SchoolScreen,
    font: "fa-thin fa-school",
    parent: "structure",
  },
  {
    label: "Université",
    layout: "/admin",
    menu: true,
    name: "structUniv",
    path: "/struct/university",
    active: true,
    component: UniversityScreen,
    font: "fa-thin fa-building-columns",
    parent: "structure",
  },
  {
    component: SubscriptionScreen,
    icon: TeamOutlined,
    layout: "/admin",
    menu: true,
    label: "Abonnements",
    name: "subscriptionSceen",
    path: "/subscriptions",
    font: "fa-solid fa-credit-card",
    parent: "payment",
  },
]