import { Button, Modal, notification } from "antd";
import { useFormik } from "formik";
import React from "react";
import { DlInput, DlTextarea } from "../../components/input";
import { isEmpty, toInteger, toString } from "lodash";
import { getUid } from "../../utils/text";
import * as yup from "yup";
import branchService from "../../services/branchService";

interface createSubjectInterface {
  id?: number | string;
  initFunction?: any;
  item?: any;
}

export function CreateBranch(
  props: createSubjectInterface = { id: 0, initFunction: {}, item: {} }
) {
  const { id, initFunction, item } = props;

  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      title: item?.title ?? "",
      description: item?.description ?? "",
    },
    validationSchema: yup.object({
      title: yup
        .string()
        .required("Champ requis")
        .max(255, "Le nombre limite des caractères est 255"),
      description: yup.string(),
    }),
    onSubmit: isEmpty(item) ? handleValidate : handleUpdate,
  });
  function handleOpen() {
    setVisible(true);
  }
  function handleClose() {
    if (!loading) {
      setVisible(false);
    }
  }
  async function handleValidate() {
    setLoading(true);
    const data = formik.values as any;
    data.subject = `/api/subjects/${getUid(toString(id))}`;
    await branchService
      .store(data)
      .then(() => {
        notification.info({
          message: "Ajout de sous-branche",
          description: `${formik.values.title} a été ajouté avec succès`,
        });
        if (typeof initFunction === "function") {
          initFunction();
        }
        setVisible(false);
        formik.handleReset((e: any) => {});
      })
      .catch((reason) => {
        console.log("REASON", reason);
        notification.error({
          message: "Erreur d'enegistrement",
          description: "L'enregistrement a échoué",
        });
      });
    setLoading(false);
  }
  async function handleUpdate() {
    setLoading(true);
    const { title, description } = formik.values;
    const data = { title, description };
    const uid = getUid(item["@id"]);
    await branchService
      .update(toString(uid), data)
      .then(() => {
        notification.info({
          message: "Modification de sous-branche",
          description: `La modification a réussi`,
        });
        if (typeof initFunction === "function") {
          initFunction();
        }
        setVisible(false);
        formik.handleReset((e: any) => {});
      })
      .catch((reason) => {
        console.log("REASON", reason);
        notification.error({
          message: "Erreur de modification",
          description: "La modification a échoué",
        });
      });
    setLoading(false);
  }
  return (
    <React.Fragment>
      {isEmpty(item) && (
        <Button onClick={handleOpen}>{`Ajouter une sous-branche`}</Button>
      )}
      {!isEmpty(item) && (
        <Button type="text" className="yc-btn-left" onClick={handleOpen}>
          {`Modifier la sous-branche`}
        </Button>
      )}
      <Modal
        visible={visible}
        confirmLoading={loading}
        centered
        title={
          isEmpty(item) ? "Nouvelle sous-banche" : "Modifier la sous-branhce"
        }
        okText={isEmpty(item) ? "Enregistrer" : "Modifier"}
        onOk={() => formik.submitForm()}
        cancelText="Annuler"
        onCancel={handleClose}
      >
        <div>
          <DlInput
            formik={formik}
            name="title"
            label="Titre de sous-branche"
            placeholder="sous-branche"
          />
          <DlTextarea
            formik={formik}
            name="description"
            label="Description"
            placeholder="Décrivez la sous-branche"
          />
        </div>
      </Modal>
    </React.Fragment>
  );
}
