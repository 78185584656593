import { InboxOutlined } from "@ant-design/icons";
import {
  Col,
  message,
  notification,
  Radio,
  Row,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { toNumber } from "lodash";
import React from "react";
import { DlInput } from "../../components/input";
import { getUid } from "../../utils/helpers/title";

interface lessonInterface {
  formik: any;
  subject: any;
  chapters: any[];
  fileList: any[];
  setFileList: any;
}

const elem: UploadProps = {
  name: "file",
  multiple: true,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      const size = info.file.size;
      const type = info.file.type;
      if (!type?.includes("image")) {
        notification.warning({
          message: "Type de fichier",
          description: "Veuillez ajouter une image",
        });
        return;
      }
      if (toNumber(size) > 1 * 1024 * 1024) {
        notification.warning({
          message: "Taille du fihier",
          description: "La taille du fichier ne peut pas dépasser 1Mo",
        });
        return;
      }
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

export function IdentityLesson(props: lessonInterface) {
  const { setFileList, fileList } = props;

  function onChange(info: UploadChangeParam<UploadFile<any>>) {
    const { status } = info.file;
    if (status !== "uploading" && typeof setFileList === "function") {
      setFileList(info.fileList);
    }
  }
  function onDrop(e: any) {
    console.log("Dropped files", e.dataTransfer.files);
  }

  function handleChangeChapter(uid: string) {
    props.formik.setValues({ ...props.formik.values, chapiter: uid });
  }

  return (
    <div>
      <Row align="stretch" gutter={4} wrap={false}>
        <Col span={8}>
          <div>
            <DlInput
              formik={props.formik}
              name="title"
              label="Titre de la leçon"
              placeholder="titre de la leçon"
            />
            <Upload.Dragger
              multiple={false}
              accept="image/*"
              name="file"
              listType="picture-card"
              onChange={onChange}
              onDrop={onDrop}
              defaultFileList={fileList}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined rev="" />
              </p>
              <p className="ant-upload-text">
                {`Cliquez ou faites glisser le fichier dans cette zone pour le télécharger`}
              </p>
              <p className="ant-upload-hint">
                {`Cette image servira à afficher la leçon`}
              </p>
            </Upload.Dragger>
          </div>
        </Col>
        <Col
          span={8}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography.Title
            level={2}
            type="secondary"
            style={{ textAlign: "center" }}
          >
            {props.subject?.title}
          </Typography.Title>
          <Typography.Paragraph style={{ textAlign: "center" }}>
            {props.subject?.description}
          </Typography.Paragraph>
        </Col>
        <Col
          span={8}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography.Title
            level={2}
            type="success"
            style={{ textAlign: "center" }}
          >
            {"Choisisez le chapitre"}
          </Typography.Title>
          <Radio.Group value={props.formik?.values.chapiter}>
            {props.chapters.map((p) => {
              const uid = getUid(p["@id"]);
              return (
                <div>
                  <Radio value={uid} onChange={() => handleChangeChapter(uid)}>
                    {p.title}
                  </Radio>
                </div>
              );
            })}
          </Radio.Group>
        </Col>
      </Row>
    </div>
  );
}
